import React, { useEffect, useState } from 'react';
import CampaignPlay from "./components/CampaignPlay"
import { PollCampaignApi } from './api';
import FlashMarket from './flashmarket';
import { View, useWindowDimensions } from 'react-native';


type PollCampaignModuleProps = {
    poll_campaign_id:string,
    poll_id?:string,
    player_id?:string,
    type: 'full' | 'mini',
    onRequestAuthenticate: (auth_strategy_id?:string, company_id?:string) => void,
    onComplete:() => void
}
const PollCampaign = ({ poll_campaign_id, poll_id, type, player_id, onRequestAuthenticate, onComplete }: PollCampaignModuleProps ) => {
    const [ loaded, setLoaded ] = useState(false);

    const dimensions = useWindowDimensions();
    
    useEffect(() => {
        PollCampaignApi.setEnvironment();
        setLoaded(true)
    },[])
    if(!loaded){ return <></> }

    if(type == 'full' && poll_campaign_id){
        let widget_width = dimensions.width
        if(widget_width > 600){ widget_width = 600 }
        return (
            <View style={{ width: widget_width, alignSelf:'center', height:dimensions.height }}>
                <CampaignPlay
                    player_id={player_id}
                    onRequestAuthenticate={onRequestAuthenticate}
                    poll_campaign_id={poll_campaign_id}
                    onFinished={onComplete}
                />
            </View>
        )
    }
    if(type == 'mini'){
        return (
            <FlashMarket
                poll_campaign_id={poll_campaign_id}
                poll_id={poll_id}
                player_id={player_id}
                onRequestAuthenticate={onRequestAuthenticate}
            />
        )
    }
    return <></>

}

export default PollCampaign