import React, { useEffect, useState } from 'react';
import { ActivityIndicator, FlatList, Image, ScrollView, TouchableOpacity, View } from 'react-native';
import type { PollCampaignProps, PollProps, PollResponseProps } from '../../types';
import Colors from '../../constants/colors';
import PollCard from './PollCard';
import CampaignProgressBar from './CampaignProgressBar';
import CampaignResult from './CampaignResult';
import PollSelectCard from './PollSelectCard';
import { PollApi, PollCampaignApi, PollCampaignHelpers, PollResponseApi } from '../api';
import { Button, Icons, Text } from '../../Components';


type CampaignPlayProps = {
    poll_campaign_id:string,
    player_id?:string,
    onActiveViewChange?:(active_view:string) => void
    onFinished: () => void,
    onRequestAuthenticate: (auth_strategy_id?:string, company_id?:string) => void,
    init_poll_id?:string,
    init_view?:string,
    onProfileSelect?: () => void
}

const CampaignPlay = ({ player_id, poll_campaign_id, onActiveViewChange, onRequestAuthenticate, onFinished, init_view, init_poll_id }:CampaignPlayProps) => {
    //const [ show_authenticate, setShowAuthenticate ] = useState(false);
    const [ campaign_data, setCampaignData ] = useState<{
        poll_campaign?:PollCampaignProps,
        polls:PollProps[],
        my_responses:PollResponseProps[]
    }>({
        polls:[],
        my_responses:[]
    })
    const [ loading, setLoading ] = useState(false);
    //const [ shareImage, setImage ] = useState<any>(undefined)
    const [ share_in_process ] = useState(false);
    const [ play_width, setPlayWidth ] = useState(0);
    const [ show_live, setShowLive ] = useState(false);
    const [ active_poll, setActivePoll ] = useState<PollProps|undefined>(undefined);
    const [ active_view, setActiveView ] = useState('select_poll')
    
    const { poll_campaign, polls, my_responses } = campaign_data
    
    polls.filter(p => p.poll_campaign_id == poll_campaign_id && ['pending','active','paused','closed'].includes(p.status)).sort((a,b) => {
        if(a.priority && b.priority){ return a.priority - b.priority }
        else { return parseInt(b.poll_id) - parseInt(a.poll_id) }
    })
    const playable_polls = polls.filter(p => ['closed','active'].includes(p.status))
    const active_polls = polls.filter(p => p.status == 'active');
    const pending_polls = polls.filter(p => ['pending','paused'].includes(p.status));
    const closed_polls = polls.filter(p => p.status == 'closed');
    
    const poll_responses = my_responses.filter(pr => polls.map(p => p.poll_id.toString()).includes(pr.poll_id.toString()))

    const campaign_complete = PollCampaignHelpers.isCampaignComplete(polls, poll_responses, poll_campaign)
    
    const active_responded = poll_responses.find(pr => pr.poll_id == active_poll?.poll_id) ? true : false;
    let active_index = playable_polls.findIndex(p => p.poll_id == active_poll?.poll_id)


    //const progressRef = useRef()

    useEffect(() => {
        getCampaignDetailsFromServer(poll_campaign_id)
    },[poll_campaign_id, player_id])

    useEffect(() => {
        if(onActiveViewChange){ return onActiveViewChange(active_view) }
    },[active_view])

    useEffect(() => {
        if(!share_in_process){ return }
        /*
        html2canvas(progressRef.current).then(function(canvas:any) {
            var myImage = canvas.toDataURL("image/png");
            setImage(myImage)
            //share(myImage)
        });
        */
    },[share_in_process])

    useEffect(() => {
        if(polls.length == 0){ return }
        if(!init_poll_id || init_poll_id == 'undefined'){ return }
        if(init_view != 'result'){ return }
        let init_poll = polls.find(p => p.poll_id == init_poll_id)
        if(!init_poll){ return }
        setActivePoll(init_poll)
        setActiveView('respond_poll')
    },[polls.length])

    const getCampaignDetailsFromServer = async(poll_campaign_id:string) => {
        setLoading(true);
        const pc = await PollCampaignApi.getPollCampaignById(poll_campaign_id)
        const ps_resp = await PollApi.getPollsByCampaignId(poll_campaign_id)
        let prs:PollResponseProps[] = []
        if(player_id){ 
            prs = await PollResponseApi.getMyCampaignResponses(poll_campaign_id)
        }
        setCampaignData({
            poll_campaign:pc,
            my_responses: prs,
            polls: ps_resp.polls
        })
        setLoading(false);
    }

    /*
    const getImage = async() => {
        if(!progressRef?.current){ return alert('Nothing to capture') }
        setShareInProcess(true)
    }
    */
    

    /*
    const share = async(newImage:string) => {
        if(!player){ return }
        if(!company){ return alert('no company')}
        let text = '';
        let url = `https://share.mybe.app/polls/${company.short_name}?poll_campaign_id=${poll_campaign.poll_campaign_id}`
        setShareInProcess(false)
        if(!newImage){ return alert('No image!') }
        if(!navigator?.share){ return alert('Browser does not support sharing')}
        const blob = await (await fetch(newImage)).blob();
        const filesArray = [
            new File([blob], `${player.player_id}_poll.png`, { type:blob.type } )
        ]
        navigator.share({ text, files:filesArray, url }).then(() => {
            //segEventTrack({ event: 'Shared Position', properties: { be_type:route.params.be_type, result:shareablePosition.result_ind } })
        }).catch((e) => console.log(e))
    }
    */

    /*

    const nativeShare = () => {
        if(!navigator?.share){ return alert('Your browser does not support native sharing') }
        if(!company){ return alert('no company')}
        let text = ``
        let total = playable_polls.length
        let total_resolved = playable_polls.filter(pp => pp.winning_option_id || pp.winning_value).length
        let wins = 0
        playable_polls.sort((a,b) => a.priority - b.priority).map(pp => {
            let resp = poll_responses.find(pr => pr.poll_id == pp.poll_id)
            if(!resp && (!pp.winning_option_id || !pp.winning_value)){ return }
            if(!resp){ text += `🕚`; return }
            if(!resp.result_ind){ text += '🕚'; return }
            if(resp.result_ind == 'win'){ wins += 1; text += '✅'; return }
            if(resp.result_ind == 'lose'){ text += '❌'; return }
            text += '🕚'
            return
        })
        let message = `I just went ${wins} out of ${total} in ${poll_campaign.name}! \n${text}`
        if(total_resolved < total){ message = `I just completed the ${poll_campaign.name}!` }
        let link = window.location.href
        //let link = `https://share.mybe.app/polls/${company.short_name}?poll_campaign_id=${poll_campaign.poll_campaign_id}`
        navigator.share({ 
            text:`${message}`,
            url: link
        }).then(() => {
            //segEventTrack({ event: 'Shared Position', properties: { be_type:route.params.be_type, result:shareablePosition.result_ind } })
        }).catch((e) => console.log(e))

    }
    */

    const handleNavPoll = (poll:PollProps) => {
        if(active_poll?.seconds_allowed){
            let responded = poll_responses.find(pr => pr.poll_id == active_poll.poll_id)
            if(!responded){ return }
        }
        if(init_view == 'result'){ return } //Dont navigate if init view is a result only
        if(active_view != 'respond_poll'){ setActiveView('respond_poll') }
        return setActivePoll(poll)
    }

    const handleSkip = () => {
        if(active_index == polls.length - 1){
            return setActivePoll(polls[0])
        }
        return setActivePoll(polls[active_index + 1])
    }

    const renderPolls = (data: {item:PollProps, index:number}) => {
        if(!poll_campaign){ return <></> }
        const selected = active_poll?.poll_id == data.item.poll_id ? true : false;
        const poll_response = poll_responses.find(pr => pr.poll_id == data.item.poll_id);
        const response_option = data.item.poll_options?.find(po => po.poll_option_id == poll_response?.poll_option_id)
        return (
            <PollSelectCard
                index={data.index}
                poll_campaign={poll_campaign}
                disabled={data.item.status == 'pending' ? true : false}
                poll={data.item}
                selected={selected}
                response_option={response_option}
                show_response={poll_response ? true : false}
                poll_response={poll_response}
                onSelect={(poll) => {
                    if(poll.status == 'pending'){ return alert('Poll is not yet active!') }
                    if(selected){ setActivePoll(undefined) }
                    else { setActivePoll(poll) }
                }}
            />
        )
    }

    if(!poll_campaign || loading){
        return (
            <View style={{ padding:20 }}>
                <ActivityIndicator style={{ alignSelf:'center' }} size='large' color={Colors.brand.midnight} />
            </View>
        )
    }

    return (
        <View style={{ flex:1, backgroundColor:Colors.shades.white }} onLayout={(ev) => {
            const { width } = ev.nativeEvent.layout;
            setPlayWidth(width)
        }}>
            <View style={{ backgroundColor:Colors.shades.shade600, padding:10 }}>
                <View style={{ flexDirection:'row' }}>
                    <Image
                        source={{ uri: poll_campaign.campaign_image?.url }}
                        style={{ height:play_width * 0.15, width:play_width * 0.25, borderRadius:8 }}
                        resizeMode='cover'
                    />
                    <View style={{ flex:1, marginLeft:10, marginRight:10, justifyContent:'center'}}>
                        <Text size={14} color={Colors.brand.midnight} weight='bold'>{poll_campaign.name}</Text>
                        <Text style={{ marginTop:3 }} size={12} color={Colors.brand.midnight} weight='regular'>{poll_campaign.total_responses} Responses</Text>
                    </View>
                    <View style={{ flexDirection:'row' }}>
                        <TouchableOpacity style={{ padding:10 }} onPress={() => console.log('NATIVE SHARE')}>
                            <Icons.ShareIcon color={Colors.brand.midnight} size={12}/>
                        </TouchableOpacity>
                        {poll_campaign.stream ?
                        <TouchableOpacity style={{ padding:10 }} onPress={() => setShowLive(!show_live)}>
                            {show_live ?
                            <Icons.CloseIcon color={Colors.utility.error} size={14}/>
                            :
                            <Icons.TVIcon color={Colors.brand.midnight} size={14} />
                            }
                        </TouchableOpacity>
                        :<></>}
                    </View>
                </View>
                {poll_campaign.stream ?
                <View nativeID='campaign_stream' style={{ marginTop: show_live ? 5: 0, height:show_live ? undefined : 0, overflow:'hidden', justifyContent:'center', alignItems:'center' }}>
                    <Text>STREAM!</Text>
                </View>
                :<></>}
            </View>
            <View style={{ flex:1 }}>
                <View nativeID='progress' style={{ padding:10, backgroundColor:Colors.shades.shade600 }}>
                    <View>
                        <CampaignProgressBar 
                            polls={playable_polls}
                            onPollSelect={(poll:PollProps) => handleNavPoll(poll)}
                            poll_responses={poll_responses}
                            active_poll={active_poll ? active_poll.poll_id : playable_polls[0]?.poll_id ?? ''}
                            poll_campaign={poll_campaign}
                        />
                    </View>
                </View>
                { active_view == 'select_poll' ? 
                <View nativeID='pre_start' style={{ flex:1 }}>
                    <ScrollView style={{ flex:1 }}>
                    <View style={{ padding:10 }}>
                        {active_polls.length > 0 ?
                        <View nativeID='active_questions'>
                            <View style={{ flexDirection:'row', alignItems:'center' }}>
                                <Text size={14} color={Colors.brand.midnight} weight='bold'>Active Questions</Text>
                                <View style={{ marginLeft:5, borderRadius:4, padding:4, backgroundColor:Colors.utility.success }}>
                                    <Text style={{ alignSelf:'center' }} size={10} color={Colors.shades.white} weight='semibold' textAlign='center'>{active_polls.length}</Text>
                                </View>
                            </View>
                            <View nativeID='active_questions_list' style={{ marginTop: 10, backgroundColor:Colors.shades.shade100, borderRadius:22 }}>
                                <FlatList
                                    data={active_polls}
                                    renderItem={renderPolls}
                                />
                            </View>
                        </View>
                        :<></>}
                        {false && pending_polls.length > 0 ?
                        <View nativeID='pending_questions' style={{ marginTop:10 }}>
                            <View style={{ flexDirection:'row', alignItems:'center' }}>
                                <Text size={14} color={Colors.brand.midnight} weight='bold'>Pending Questions</Text>
                                <View style={{ marginLeft:5, borderRadius:4, padding:4, backgroundColor:Colors.brand.slate }}>
                                    <Text style={{ alignSelf:'center' }} size={10} color={Colors.shades.white} weight='semibold' textAlign='center'>{pending_polls.length}</Text>
                                </View>
                            </View>
                            {false ?
                            <View nativeID='pending_questions_list' style={{ marginTop: 10, backgroundColor:Colors.brand.slate, borderRadius:22 }}>
                                <FlatList
                                    data={pending_polls}
                                    renderItem={renderPolls}
                                />
                            </View>
                            :<></>}
                        </View>
                        :<></>}
                        {closed_polls.length > 0 ?
                        <View nativeID='closed_questions' style={{ marginTop:10 }}>
                            <View style={{ flexDirection:'row', alignItems:'center' }}>
                                <Text size={14} color={Colors.brand.midnight} weight='bold'>Closed Questions</Text>
                                <View style={{ marginLeft:5, borderRadius:4, padding:4, backgroundColor:Colors.utility.success }}>
                                    <Text style={{ alignSelf:'center' }} size={10} color={Colors.shades.white} weight='semibold' textAlign='center'>{closed_polls.length}</Text>
                                </View>
                            </View>
                            <View nativeID='closed_questions_list' style={{ marginTop: 10, backgroundColor:Colors.shades.shade100, borderRadius:22 }}>
                                <FlatList
                                    data={closed_polls}
                                    renderItem={renderPolls}
                                />
                            </View>
                        </View>
                        :<></>}
                    </View>
                    </ScrollView>
                    <View style={{ flexDirection:'row', padding:10, borderRadius:22 }}>
                        <Button
                            style={{flex:1, marginRight:4}}
                            title='Exit'
                            backgroundColor={Colors.utility.error}
                            title_size={12}
                            padding={14}
                            title_color={Colors.shades.white}
                            borderRadius={22}
                            onPress={() => onFinished()}
                        />
                        <Button
                            style={{flex:2}}
                            title_size={12}
                            padding={14}
                            disabled={active_poll?false:true}
                            title={active_poll?active_responded||active_poll.status!='active'?'View Question':'Respond':'Select Question'}
                            title_color={Colors.shades.white}
                            backgroundColor={active_poll?Colors.utility.success:Colors.brand.slate}
                            borderRadius={22}
                            onPress={() => {
                                if(!player_id){ return onRequestAuthenticate(poll_campaign.auth_strategy_id, poll_campaign.company_id) }
                                setActiveView('respond_poll')
                            }}
                        />
                    </View>
                    <View style={{ flexDirection:'row', padding:10, backgroundColor:Colors.shades.shade100, borderBottomLeftRadius:22, borderBottomRightRadius:22 }}>
                        {campaign_complete && active_view == 'select_poll' ?
                            <Button
                                icon_name='trophy'
                                icon_side='left'
                                title='Results'
                                title_size={12}
                                backgroundColor={Colors.incentive.gold}
                                title_color={Colors.shades.white}
                                borderRadius={22}
                                onPress={() => setActiveView('campaign_results')}
                            />
                        :<></>}
                    </View>
                </View>
                : active_view == 'respond_poll' && active_poll ?
                        <View nativeID='respond_poll' style={{ flex:1 }}>
                            <PollCard
                                poll={active_poll}
                                view={init_view == 'result' ? 'result' : 'play'}
                                question_number={active_index + 1}
                                onShowAuthenticate={() => {
                                    console.log('show authenticate!!')
                                }}
                                onResponse={(response) => {
                                    setCampaignData({
                                        ...campaign_data,
                                        my_responses: my_responses.filter(r => r.poll_response_id != response.poll_response_id).concat(response)
                                    })
                                }}
                                total_questions={playable_polls.length}
                                onSkip={() => handleSkip()}
                                onSeeResults={() => {
                                    if(campaign_complete){ return setActiveView('campaign_results') }
                                    return handleSkip()
                                }}
                                onCancel={() => {
                                    setActivePoll(undefined)
                                    setActiveView('select_poll')
                                }}
                            />
                        </View>
                : active_view == 'campaign_results' ?
                    <View nativeID='campaign_results' style={{ flex:1 }}>
                        <CampaignResult
                            poll_campaign={poll_campaign}
                            polls={polls}
                            //hide_back={init_view && init_view !='undefined' ? true : false}
                            onShare={() => console.log('Share this bad boi!')}
                            poll_responses={poll_responses}
                            onClose={() => setActiveView('select_poll')}
                        />
                    </View>
                :<></>}
            </View>
            
        </View>
    )

}

export default CampaignPlay