import React, { useEffect, useState } from 'react';
import { ActivityIndicator, View, Image, TouchableOpacity } from "react-native";
import type { PollCampaignProps, PollOptionProps, PollProps, PollResponseProps, PollSummaryProps } from '../../types';
import Colors from '../../constants/colors';
import { Button, Icons, Text, TextInput } from '../../Components';
import { PollApi, PollCampaignApi, PollCampaignHelpers, PollResponseApi, PollResponseHelpers } from '../api';
import { view_styles } from '../../constants/styles';
import AsyncStorage from '@react-native-async-storage/async-storage';
import CampaignProgressBar from '../components/CampaignProgressBar';
import ResponseTimer from '../components/ResponseTimer';
import ResultCard from './ResultCard';


type FlashMarketProps = {
    poll_campaign_id:string,
    poll_id?:string,
    hide_progress_bar?:boolean,
    next_allowed?:boolean,
    player_id?:string,
    onRequestAuthenticate:(auth_strategy_id?:string, company_id?:string) => void
}

const MAX_IMAGE_SIZE = 125
const MAX_OPTION_WIDTH = 200
const MIN_OPTION_WIDTH = 120

const FlashMarket = ({ hide_progress_bar, poll_campaign_id, poll_id, player_id, onRequestAuthenticate }:FlashMarketProps) => {
    const [ flash_size, setFlashSize ] = useState({ height:0, width:0 });
    const [ poll_data, setPollData ] = useState<{
        loading:boolean,
        poll?:PollProps,
        poll_options:PollOptionProps[],
        poll_summaries: PollSummaryProps[],
        poll_response?:PollResponseProps,
        show_complete: boolean
    }>({
        loading: false,
        poll_options: [],
        show_complete:false,
        poll_summaries: [],
    })
    const { loading, poll, poll_options, poll_summaries, poll_response } = poll_data;

    const [ data, setData ] = useState<
    {
        loaded:boolean,
        poll_campaign?:PollCampaignProps,
        active_poll_index?:number,
        show_leaders:boolean,
        polls:PollProps[],
        poll_responses:PollResponseProps[]
    }
    >({
        loaded:false,
        polls:[],
        show_leaders: false,
        poll_responses: []
    })

    const { loaded, poll_campaign, polls, active_poll_index, poll_responses, show_leaders } = data;

    let visible_options = poll_options
    if(poll_response && poll_response.status == 'pending'){ visible_options = poll_options.filter(po => po.poll_option_id == poll_response.poll_option_id) }
    const selected_option = poll_options.find(po => po.poll_option_id == poll_response?.poll_option_id);
    let image_size = flash_size.width * 0.15
    if(image_size > MAX_IMAGE_SIZE){ image_size = MAX_IMAGE_SIZE }
    let option_width = (flash_size.width / 3) - 5
    if(option_width > MAX_OPTION_WIDTH){ option_width = MAX_OPTION_WIDTH }
    if(option_width < MIN_OPTION_WIDTH){ option_width = MIN_OPTION_WIDTH }

    useEffect(( ) => {
        getDataFromServer(poll_campaign_id)
    },[poll_campaign_id, player_id])

    useEffect(() => {

        if(!active_poll_index && active_poll_index != 0){ return }
        let active_poll = polls[active_poll_index]
        if(!active_poll){ return }
        getPollFromServer(active_poll.poll_id)
    },[active_poll_index])

    const getDataFromServer = async(poll_campaign_id:string) => {
        const pc = await PollCampaignApi.getPollCampaignById(poll_campaign_id)
        const ps_response = await PollApi.getPollsByCampaignId(poll_campaign_id)
        let prs:PollResponseProps[] = []
        if(player_id){ 
            prs = await PollResponseApi.getMyCampaignResponses(poll_campaign_id)
        }
        //if(poll_id){ await getPollFromServer(poll_id) }
        let index = polls.findIndex(p => p.poll_id == poll_id)
        if(pc.campaign_type == 'trivia'){ index = -1 }

        if(poll){ index = polls.findIndex(p => p.poll_id == poll.poll_id) }

        //const campaign_complete = PollCampaignHelpers.isCampaignComplete(ps_response.polls, prs, pc)


        setData({
            ...data,
            loaded:true,
            polls:ps_response.polls.sort((a,b) => a.priority - b.priority),
            poll_campaign: pc,
            //show_leaders: campaign_complete,
            active_poll_index:index >= 0 ? index: undefined,
            poll_responses: prs
        })
    }

    const getPollFromServer = async(poll_id:string) => {
        setPollData({ ...poll_data, loading:true });

        const p = await PollApi.getPollById(poll_id);
        let my_response:PollResponseProps | undefined = poll_responses.find(pr => pr.poll_id == poll_id)

        if(!my_response){
            //If no-response - check cache
            let cached_response = await AsyncStorage.getItem(`response:${poll_id}`)
            if(cached_response){
                my_response = JSON.parse(cached_response);
            }
        }

        setPollData({
            ...poll_data,
            loading:false,
            poll: p.poll,
            poll_options: p.poll_options,
            poll_summaries: p.poll_summaries,
            poll_response: my_response
        })
    }

    const handleSelectOption = async(po:PollOptionProps) => {
        if(!poll || poll.status != 'active'){ return }
        if(poll_response && poll_response.status != 'pending'){ return setPollData({ ...poll_data, show_complete: true }) }
        let cached_response = await AsyncStorage.getItem(`response:${poll_id}`)
        if(cached_response){
            if(JSON.parse(cached_response).poll_option_id == po.poll_option_id){ 
                await AsyncStorage.removeItem(`response:${poll_id}`)
                return setPollData({ ...poll_data, poll_response: undefined }) 
            }
        }
        let stake = poll_response?.stake ?? poll.minimum_stake
        let draft_response:PollResponseProps = {
            poll_id:poll.poll_id,
            poll_option_id:po.poll_option_id,
            stake: stake,
            market_type: 'FREE',
            poll_response_id: '',
            player_id: '',
            winnings: 0,
            response_body: {},
            status: 'pending',
            create_datetime: '',
            last_update_datetime: ''
        }
        await AsyncStorage.setItem(`response:${poll_id}`, JSON.stringify(draft_response))
        
        setPollData({
            ...poll_data,
            poll_response: draft_response
        })
    }

    const handleTimesUp = async() => {
        if(!poll || poll.status != 'active'){ return }
        if(!player_id){ return }
        if(poll_response && poll_response.status == 'pending'){ return handleSubmit() }

        const draft_response:PollResponseProps = {
            poll_id:poll.poll_id,
            poll_option_id:'0',
            stake: poll.minimum_stake,
            market_type: 'FREE',
            poll_response_id: '',
            player_id: '',
            winnings: 0,
            timed_out: true,
            response_body: {},
            status: 'pending',
            create_datetime: '',
            last_update_datetime: ''
        }

        const new_resp = await PollResponseApi.createPollResponse({ ...draft_response })
        const updated_ps = PollResponseHelpers.updateSummariesWithResponse(new_resp, poll_summaries)
        setPollData({
            ...poll_data,
            poll_response: new_resp,
            poll_summaries: updated_ps,
            show_complete: true
        })
        setData({
            ...data,
            poll_responses: poll_responses.concat(new_resp)
        })

        await AsyncStorage.removeItem(`response:${poll_id}`)

    }
    
    const handleSubmit = async() => {
        if(!poll_response){ return }
        if(poll_response.status != 'pending'){ return }
        if(!player_id){ return onRequestAuthenticate(poll_campaign?.auth_strategy_id, poll_campaign?.company_id) }
        let stake_number = parseFloat(poll_response.stake as string)
        if(isNaN(stake_number)){ return }
        const new_resp = await PollResponseApi.createPollResponse({ ...poll_response, stake: stake_number })
        const updated_ps = PollResponseHelpers.updateSummariesWithResponse(new_resp, poll_summaries)
        setPollData({
            ...poll_data,
            poll_response: new_resp,
            poll_summaries: updated_ps,
            show_complete: true
        })
        setData({
            ...data,
            poll_responses: poll_responses.concat(new_resp)
        })
        await AsyncStorage.removeItem(`response:${poll_id}`)
    }


    //const selected_option = poll_options.find(po => po.poll_option_id == poll_response?.poll_option_id)
    //const selected_summary = poll_summaries.find(ps => ps.poll_option_id == poll_response?.poll_option_id)
    //const correct_option = poll_options.find(po => po.result_ind == 'win');
    //const correct_summary = poll_summaries.find(ps => ps.poll_option_id == correct_option?.poll_option_id);

    const renderPollOptions = (data:{item:PollOptionProps, show_summary?:boolean, index:number}) => {
        const poll = polls.find(p => p.poll_id == data.item.poll_id)
        if(!poll){ return <></> }
        const selected = data.item.poll_option_id == poll_response?.poll_option_id ? true : false
        const poll_summary = poll_summaries.find(ps => ps.poll_option_id == data.item.poll_option_id);
        const color = PollResponseHelpers.getOptionColor(poll, data.item, poll_response)
        return (
            <View style={{ width:option_width }}>
                
                <TouchableOpacity 
                //disabled={poll_response && poll_response.status != 'pending' ? true : false}
                style={{ flex:1, flexDirection:'row', flexWrap:'wrap', alignItems:'center', margin:5, borderRadius:22, padding:10, borderWidth:selected?1:0, borderColor:Colors.brand.midnight, backgroundColor:color, ...view_styles.float }}
                onPress={() => handleSelectOption(data.item)}>
                    {data.show_summary && poll_summary ?
                    <View style={{ position:'absolute', top:0, left:0, bottom:0, borderRadius:22, backgroundColor:color, opacity:0.6, width: (option_width * poll_summary.pct) - 20 }}/>
                    :<></>}
                    <Text style={{ flex:1 }} size={14} color={Colors.brand.midnight} weight={'bold'} textAlign={data.show_summary?'left':'center'}>{data.item.option_name}</Text>
                    {data.show_summary && poll_summary ?
                    <Text style={{ marginLeft:10, padding:5, borderRadius:selected?100:0, backgroundColor:selected?Colors.shades.white:undefined }} size={12} color={Colors.brand.midnight} weight='bold' >{(poll_summary.pct*100).toFixed()}%</Text>
                    :<></>}
                </TouchableOpacity>
            </View>
        )
    }

    if(!loaded || !poll_campaign){
        return (
            <View>
                <ActivityIndicator size={'large'} color={Colors.brand.midnight}/>
            </View>
        )
    }


    const next_allowed = PollResponseHelpers.nextAllowed(polls, poll_responses, poll?.poll_id)
    const campaign_complete = PollCampaignHelpers.isCampaignComplete(polls, poll_responses, poll_campaign)

    return (
        <View style={{ flex:1, backgroundColor:Colors.shades.white }}>
            <View style={{ flex:1 }} onLayout={(ev) => {
                const { height, width } = ev.nativeEvent.layout;
                setFlashSize({ height, width })
                }}>
                {show_leaders ?
                <View>
                    <ResultCard
                        poll_campaign_id={poll_campaign_id}
                        width={flash_size.width}
                        polls={polls}
                        poll_responses={poll_responses}
                        onClose={() => setData({ ...data, show_leaders: false, active_poll_index:0 })}
                    />
                </View>
                :!poll ?
                    <View style={{ flexDirection:'row', alignItems:'center' }}>
                        <View style={{ padding:10 }}>
                            <Image
                                source={{ uri: poll_campaign.campaign_image?.url ?? 'https://res.cloudinary.com/hoabts6mc/image/upload/v1718979933/question_mark_ro0ac5.webp' }}
                                style={{ height:image_size, width:image_size }}
                                resizeMode='cover'
                            />
                        </View>
                        <View style={{ flex:1 }}>
                            <Text size={18} color={Colors.brand.midnight} weight='bold'>{poll_campaign.name}</Text>
                            <Text style={{ marginTop:5 }} size={16} color={Colors.brand.midnight} weight='semibold'>{poll_campaign.total_polls} Questions</Text>
                        </View>
                        {campaign_complete ?
                        <Button
                            style={{ margin:5 }}
                            title='RESULTS'
                            padding={15}
                            backgroundColor={Colors.incentive.gold}
                            title_color={Colors.shades.white}
                            onPress={() => {
                                setData({ ...data, show_leaders:true })
                            }}
                        />
                        :
                        <Button
                            style={{ margin:5 }}
                            title='PLAY'
                            padding={15}
                            backgroundColor={Colors.brand.electric}
                            title_color={Colors.shades.white}
                            onPress={() => {
                                if(poll_campaign.campaign_type == 'trivia' && !player_id){ return onRequestAuthenticate(poll_campaign.auth_strategy_id, poll_campaign.company_id) }
                                setData({ ...data, active_poll_index:0 })
                            }}
                        />
                        }
                    </View>
                :
                <View>
                {!hide_progress_bar && poll ?
                <View style={{ backgroundColor:Colors.shades.shade600 }}>
                    <CampaignProgressBar
                        poll_campaign={poll_campaign}
                        poll_responses={poll_responses}
                        polls={polls}
                        onPollSelect={(p) => {
                            
                            if(poll.status == 'active' && (!poll_response || poll_response.status == 'pending' && poll.seconds_allowed)){ return  }
                            let index = polls.findIndex(ps => ps.poll_id == p.poll_id)
                            setData({ ...data, active_poll_index:index })
                        }}
                        active_poll={poll.poll_id}
                    />
                </View>
                :<></>}
                    <View style={{ width: flash_size.width, flexDirection:'row', flexWrap:'wrap' }}>
                        <View style={{ flex:1, flexGrow:2, minWidth:300  }}>
                            <View style={{ flex:1, flexDirection:'row', alignItems:'center', padding:10, borderBottomWidth:1, borderBottomColor:Colors.shades.shade600 }}>
                                <View style={{ padding:10, justifyContent:'center', alignItems:'center' }}>
                                    <Image
                                        source={{ uri: poll.poll_image?.url ?? 'https://res.cloudinary.com/hoabts6mc/image/upload/v1718979933/question_mark_ro0ac5.webp' }}
                                        style={{ height:image_size, width:image_size }}
                                        resizeMode='center'
                                    />
                                    {poll.status == 'active' && poll.seconds_allowed && (!poll_response || poll_response.status == 'pending')?
                                    <View style={{ justifyContent:'center', alignItems:'center'}}>
                                            <ResponseTimer
                                                poll_id={poll.poll_id}
                                                seconds={poll.seconds_allowed}
                                                onTimesUp={() => handleTimesUp()}
                                            />
                                    </View>
                                    :
                                    <View style={{ flexDirection:'row', alignItems:'center', borderRadius:22, backgroundColor:poll.status == 'active' ? Colors.utility.success : Colors.utility.error, padding:5, paddingRight:10 }}>
                                        {poll.status == 'active' ?
                                        <Icons.InProgressIcon size={18} color={Colors.shades.white} />
                                        :
                                        <Icons.LockClosedIcon size={16} color={Colors.shades.white} />
                                        }
                                        <Text style={{ marginLeft:4 }} size={12} color={Colors.shades.white} weight='bold'>{poll.status.toUpperCase()}</Text>
                                    </View>
                                    }
                                </View>
                                <View style={{ flex:1, paddingRight:10 }}>
                                    {loading ?
                                    <ActivityIndicator size='large' color={Colors.brand.midnight} style={{ alignSelf:'center' }} />
                                    :
                                    <Text size={16} color={Colors.brand.midnight} weight='bold'>{poll.poll_question}</Text>
                                    }
                                </View>
                            </View>
                        </View>
                        
                        <View style={{ flexGrow:1, flexDirection:'row' }}>
                            <View nativeID='poll_options' style={{ flex:1, maxWidth:flash_size.width, padding:5, flexDirection:'row', flexWrap:'wrap', alignItems:'center'}}>
                                {visible_options.sort((a,b) => a.priority - b.priority).map((po, i) => {
                                    return renderPollOptions({ item:po, index:i, show_summary:(poll_response && poll_response.status != 'pending') || poll.status == 'closed' ? true : false })
                                })}
                            </View>
                            {poll_response?.status == 'pending' ?
                                <View style={{ flex:1, justifyContent:'space-between', backgroundColor:Colors.shades.shade600 }}>
                                    <View style={{ padding:10, borderBottomWidth:1, borderBottomColor:Colors.shades.shade100 }}>
                                        <Text size={16} color={Colors.brand.midnight} textAlign='center' weight='bold'>{poll.confidence_allowed ? 'HOW MUCH?': 'ARE YOU SURE?'}</Text>
                                    </View>
                                    {poll.confidence_allowed ?
                                    <View style={{ flex:1, padding:5 }}>
                                        <View style={{flexDirection:'row', backgroundColor:Colors.shades.white, borderRadius:22 }}>
                                            <Text style={{ padding:10 }}>{poll.market_type == 'FOR_MONEY' ? '$' : 'E'}</Text>
                                            <TextInput
                                                style={{ padding:10, borderWidth:0, borderTopLeftRadius:0, borderBottomLeftRadius:0, backgroundColor:'transparent', width: flash_size.width * 0.22,}}
                                                autoFocus
                                                value={poll_response.stake as string}
                                                onChangeText={(text) => setPollData({
                                                    ...poll_data,
                                                    poll_response: { ...poll_response, stake:text }
                                                })}
                                            />
                                        </View>
                                    </View>
                                    :<View style={{flex:1, padding:5}} />}
                                    <View style={{ flexDirection:'row' }}>
                                        {selected_option ?
                                        <Button
                                            title='X'
                                            style={{ borderRadius:0 }}
                                            padding={15}
                                            title_color={Colors.shades.white}
                                            backgroundColor={Colors.utility.error}
                                            onPress={() => handleSelectOption(selected_option)}
                                        />
                                        :<></>}
                                        <Button
                                            title='SUBMIT'
                                            style={{ flex:1, borderRadius:0 }}
                                            padding={15}
                                            title_color={Colors.shades.white}
                                            backgroundColor={Colors.utility.success}
                                            onPress={() => handleSubmit()}
                                        />
                                    </View>
                                </View>
                            :<></>}
                        </View>
                    </View>
                </View>
                }
            </View>
            {next_allowed && !show_leaders ?
            <TouchableOpacity 
                style={{ position:'absolute', bottom: 10, right:10, backgroundColor:Colors.brand.electric, ...view_styles.float, borderRadius:100, height:38, width:38, justifyContent:'center', alignItems:'center' }}
                onPress={() => {
                    if(!active_poll_index && active_poll_index != 0){ return setData({ ...data, active_poll_index: 0 }) }
                    let last_poll = active_poll_index == polls.length -1 ? true : false
                    if(last_poll && campaign_complete){ return setData({ ...data, show_leaders:true }) }
                    setData({ ...data, active_poll_index: active_poll_index + 1 })
                }}
                >
                <Icons.ChevronIcon direction='right' color={Colors.shades.white} size={14} />
            </TouchableOpacity>
            :<></>}
        </View>
    )
}

export default FlashMarket