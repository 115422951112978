import React from 'react';
import { ActivityIndicator, FlatList, TouchableOpacity, View, ScrollView } from 'react-native';
import { view_styles } from '../../constants/styles';
import { Text } from '../../Components';
import Colors from '../../constants/colors';
import type { PlayerSquareProps, SquareProps } from '../../types';
import BidToggle from './BidToggle';

type BidFormProps = {
    player_id?:string,
    market_type:string,
    home_abbr?:string,
    width:number,
    away_abbr?:string,
    submit_ready: { is_loading:boolean, is_ready:Boolean }
    onSubmitBid: () => void,
    onClearBids: () => void,
    onBidAmountChange: (player_bid:PlayerSquareProps, square:SquareProps, direction: 'increase'|'decrease') => void,
    onRequestAuthenticate: () => void,
    square_bids:PlayerSquareProps[],
    squares:SquareProps[]
}
const BidForm = ({ player_id, width, market_type, home_abbr, away_abbr, submit_ready, square_bids, squares, onSubmitBid, onClearBids, onBidAmountChange, onRequestAuthenticate }:BidFormProps) => {
    const cl = market_type == 'FOR_MONEY' ? '$' : 'E'
    const total_bid_amount = square_bids.reduce((a,b) => a + b.purchase_price, 0)


    const renderBids = (data: { item:PlayerSquareProps, index:number }) => {
        let square = squares.find(s => s.sq_square_id == data.item.sq_square_id)
        if(!square){ return ( <></> ) }
        return (
            <View style={{ ...view_styles.body_row, borderBottomWidth:1, borderColor:Colors.shades.shade600 }}>
                <View style={{flex:1}}>
                    <Text theme="body">SQUARE: {home_abbr}: {square.square_score_x}, {away_abbr}: {square.square_score_y}</Text>
                    <Text theme="body_2">Current Bid: {cl}{square.last_purchase_price.toFixed(2)}</Text>
                </View>
                <View>
                    <BidToggle sq_square_id={data.item.sq_square_id} amount={data.item.purchase_price} cl={cl}  onAmountChange={(dir) => onBidAmountChange(data.item, square, dir)}/>
                </View>
            </View>
        )
    }


    return (
        <View style={{ ...view_styles.section, width, padding:0, maxHeight:550, maxWidth:600 }}>
            <ScrollView style={{ flex:1 }}>
            <View style={ view_styles.section_header }>
                <View style={{ flex:1 }}>
                    <Text theme="header">SET BID AMOUNTS</Text>
                    <Text style={{ marginTop:3 }} color={Colors.utility.error} size={12} weight='regular'>All purchases are final and cannot be removed</Text>
                </View>
                <View style={{ height:24, width:24, borderRadius:100, backgroundColor:Colors.highlights.highlight200, justifyContent:'center', alignItems:'center', marginLeft: 5 }}>
                    <Text size={12} color={Colors.shades.white} textAlign='center'>{square_bids.length}</Text>
                </View>
            </View>
            <View style={{ ...view_styles.section_body }}>
                <FlatList data={square_bids.sort((a,b) => parseInt(a.sq_square_id) - parseInt(b.sq_square_id))} renderItem={renderBids} keyExtractor={(item) => `b-${item.sq_square_id}`}/>
                <View style={view_styles.body_row}>
                    <Text style={{ flex:1 }} theme='body'>Total</Text>
                    <Text theme='header_2'>{cl}{total_bid_amount.toFixed(2)}</Text>
                </View>
                <View style={{ ...view_styles.body_row }}>
                    <TouchableOpacity style={{ flex:1/3, padding:12, borderRadius:4, borderWidth:1, borderColor:Colors.brand.cobalt, justifyContent:'center', alignItems:'center', marginRight:4 }} onPress={() => onClearBids()}>
                        <Text size={14} weight='semibold' textAlign='center' color={Colors.brand.cobalt}>Clear</Text>
                    </TouchableOpacity>
                    {player_id ?
                    <TouchableOpacity style={{ flex:2/3, padding:12, borderRadius:4, backgroundColor:submit_ready.is_ready?Colors.highlights.highlight400:Colors.highlights.highlight200, justifyContent:'center', alignItems:'center', marginLeft:4, opacity:submit_ready.is_loading?0.5:1 }} onPress={() => onSubmitBid()}>
                        {submit_ready.is_loading ?
                        <ActivityIndicator size='small' color={Colors.shades.white}/>
                        :
                        <Text size={14} weight='semibold' textAlign='center' color={Colors.shades.white}>{submit_ready.is_ready?'Submit Bids':'Review Bids'}</Text>
                        }
                    </TouchableOpacity>
                    :
                    <TouchableOpacity style={{ flex:2/3, padding:12, borderRadius:4, backgroundColor:Colors.highlights.highlight400, justifyContent:'center', alignItems:'center', marginLeft:4 }} onPress={() => onRequestAuthenticate()}>
                        <Text size={14} weight='semibold' textAlign='center' color={Colors.shades.white}>Sign Up</Text>
                    </TouchableOpacity>
                    }
                </View>
            </View>
            </ScrollView>
        </View>
    )
}

export default BidForm