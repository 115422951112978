import React, { useState } from "react"
import { ActivityIndicator, FlatList, TouchableOpacity, View } from "react-native"
import type { CompetitionMatchMarketProps, MarketProps, PlayerPickProps } from "../../types"
import Colors from "../../constants/colors"
import { Icons, Text } from "../../Components"
import { CompetitionHelpers } from "../api"


type MarketsCardProps = {
    competition_match_markets:CompetitionMatchMarketProps[]
    markets:MarketProps[],
    player_picks: PlayerPickProps[]
    mode: 'view'|'play'
    orientation?: 'vertical'|'horizontal'
    disabled?:boolean;
    locked?:boolean;
    onMarketSelect:(cmm:CompetitionMatchMarketProps, player_pick?:PlayerPickProps) => void
}
const MarketsCard = ({ competition_match_markets, markets, orientation, onMarketSelect, player_picks, locked, disabled, mode }:MarketsCardProps) => {
    const [ markets_width, setMarketsWidth ] = useState(0)

    let unique_market_ids = [ ...new Set(competition_match_markets.map(cmm => cmm.market_id))]
    const getPickColor = (player_pick:PlayerPickProps) => {
        if(locked){ return Colors.shades.shade600 }
        switch(player_pick.result_ind){
            case 'win': return Colors.utility.success
            case 'lose': return Colors.utility.error
            case 'draw': return Colors.brand.slate
            default: return Colors.highlights.highlight200
        }
    }

    const renderMarkets = (data:{item:string, index:number}) => {
        const market = markets.find(m => m.market_id == data.item)
        const match_markets = competition_match_markets.filter(cmm => cmm.market_id == data.item)
        if(!market){ return <></> }

        const market_width = orientation == "horizontal" ? markets_width : markets_width / unique_market_ids.length

        return (
            <View style={{ flex:1, flexDirection:orientation == 'horizontal'?'row':'column', width: market_width }}>
                {orientation == 'horizontal' ?
                <></>
                :
                <Text size={12} color={Colors.shades.black} weight='regular' textAlign="center">{market.type.toUpperCase()}</Text>
                }
                {match_markets.sort((a) => {
                    if(a.side == market.trade_side){ return 1 } else { return -1}
                }).map(mm => {
                    const player_pick = player_picks.find(pp => pp.market_id == data.item && pp.side == mm.side)
                    return (
                        <TouchableOpacity
                            delayPressIn={0} 
                            disabled={locked || mode == 'view' || disabled}
                            onPress={() => onMarketSelect(mm, player_pick)}
                            style={{ 
                                flex:1, marginTop:3, justifyContent:'center', padding:7.5, 
                                backgroundColor: player_pick ? getPickColor(player_pick) : Colors.shades.shade600, 
                                borderRadius:4, 
                                margin:3,
                                opacity: disabled ? 0.5 : 1
                            }}>
                            {market.var_1_required ?
                            <Text size={12} color={player_pick&&!locked?Colors.shades.white:Colors.shades.black} textAlign="center">{mm.side=='over'?'O ':mm.side=='under'?'U ':''}{CompetitionHelpers.getVar1Label(mm.var_1 as number, market)}</Text>
                            :market.show_side_option ?
                            <Text size={12} color={player_pick&&!locked?Colors.shades.white:Colors.shades.black} textAlign="center">{mm.side.toUpperCase()}</Text>
                            :<></>}
                            <Text size={12} color={player_pick&&!locked?Colors.shades.white:Colors.shades.black} textAlign="center" weight='bold'>{CompetitionHelpers.getOddsLabel(mm.odds as number)}</Text>
                            {locked ?
                            <View style={{ position:'absolute', backgroundColor:Colors.shades.shade600, borderRadius:4, top:0, bottom:0, right:0, left:0, justifyContent:'center', alignItems:'center' }}>
                                <Icons.LockClosedIcon size={14} color={Colors.shades.black} />
                            </View>
                            :<></>}
                        </TouchableOpacity>
                    )
                })}
            </View>
        )
    }
    return (
        <View style={{ flex:1 }} onLayout={(ev) => {
            const { width } = ev.nativeEvent.layout;
            setMarketsWidth(width)
        }}>
            {markets_width > 0 ?
            <View>
                {unique_market_ids.length > 0 ?
                <FlatList
                    horizontal
                    data={unique_market_ids}
                    renderItem={renderMarkets}
                />
                :
                <View style={{ height:100, justifyContent:'center', alignItems:'center' }}>
                    <Icons.LockClosedIcon color={Colors.brand.midnight} size={32}/>
                </View>
                }
            </View>
            :
            <View style={{ height:100, justifyContent:'center', alignItems:'center' }}>
                <ActivityIndicator size={'large'} color={Colors.brand.midnight}/>
            </View>
            }
        </View>
    )
}

export default MarketsCard