import React, { useEffect, useState } from 'react';
import { View, ActivityIndicator, FlatList, ScrollView } from 'react-native';
import type { BracketCompetitionProps, BracketProps, CompanyProps, CompetitionPayoutTypeProps, CompetitionProps, CompetitionResultTypeProps, CompetitionSeasonProps, CompetitionTypeProps, EventProps, LeagueProps, PublicPlayerProps, SquaresCompetitionProps } from '../types';
import Colors from '../constants/colors';
import { EngageApi, EngageHelpers } from './api';
import CompetitionCard from './components/CompetitionCard';
import { view_styles } from '../constants/styles';
import { Button, Icons, Text } from '../Components';
import SquaresCompetitionCard from './components/SquaresCompetitionCard';
import BracketCompetitionCard from './components/BracketCompetitionCard';
import moment from 'moment-mini';
import SocketManager from '../Socket';
import SeasonCard from './components/SeasonCard';
import EngageHeader from './components/EngageHeader';
import PrivateCodePrompt from './components/PrivateCodePrompt';


type EngageModuleProps = {
    player_id?:string,
    onManage:() => void,
    onCreateSeason?:() => void,
    onCreateCompetition?:() => void,
    onCreateSquares?: () => void,
    onCreateBracketCompetition?:() => void,
    onSelectCompetition:(c:CompetitionProps) => void,
    onSelectBracketCompetition:(bc:BracketCompetitionProps) => void,
    onSelectSquaresCompetition:(sc:SquaresCompetitionProps) => void,
    onSelectCompetitionSeason:(cs:CompetitionSeasonProps) => void
}

const EngageModule = ({ onSelectBracketCompetition, onSelectCompetition, onSelectSquaresCompetition, onSelectCompetitionSeason,
    onCreateSeason, onCreateCompetition, onCreateBracketCompetition, onCreateSquares, onManage
 }:EngageModuleProps) => {
    const [ module_size, setModuleSize ] = useState({ width:0, height:0 });
    const [ show_code_prompt, setShowCodePrompt ] = useState(false);
    const [ socket_state, setSocketState ] = useState<{
        connected:boolean,
        reload_needed?:boolean
    }>({
        connected: false
    });
    const [ active_tab, setActiveTab ] = useState('competitions');
    const [ module_data, setModuleData ] = useState<{
        loading:boolean,
        competitions:CompetitionProps[],
        competition_seasons:CompetitionSeasonProps[],
        competition_types:CompetitionTypeProps[],
        competition_result_types:CompetitionResultTypeProps[],
        competition_payouts:CompetitionPayoutTypeProps[],
        events:EventProps[],
        leagues:LeagueProps[],
        brackets:BracketProps[],
        squares_competitions:SquaresCompetitionProps[],
        bracket_competitions:BracketCompetitionProps[],
        companies:CompanyProps[],
        players:PublicPlayerProps[],
    }>({
        loading:false,
        competitions: [],
        competition_seasons:[],
        companies:[],
        leagues:[],
        events:[],
        brackets:[],
        competition_types: [],
        competition_result_types: [],
        competition_payouts: [],
        squares_competitions: [],
        bracket_competitions:[],
        players:[]
    });
    const { loading, competitions, competition_seasons, competition_result_types, squares_competitions, brackets, leagues, bracket_competitions, events, competition_types, companies, players } = module_data;

    useEffect(() => {
        EngageApi.setEnvironment();
        getDataFromServer()
    },[])

    useEffect(() => {
        if(!socket_state.connected){ return }
        if(socket_state.connected && !socket_state.reload_needed){ return }
        getDataFromServer()
    },[socket_state.connected])

    const getDataFromServer = async() => {
        setModuleData({ 
            ...module_data,
            loading:true,
            
        })
        const lgs = await EngageApi.getLeagues();
        const comps = await EngageApi.getActivePublicCompetitions();
        const seasons = await EngageApi.getActivePublicSeasons();
        const brackets = await EngageApi.getActivePublicBrackets();
        const squares = await EngageApi.getActivePublicSquares();
        const company_ids = EngageHelpers.getCompanyIdsFromEngage(comps, squares, brackets);
        const cpanys = await EngageApi.getCompaniesByIds(company_ids);
        const player_ids = EngageHelpers.getPlayerIdsFromEngage(comps, squares, brackets);
        const plyers = await EngageApi.getPlayersByPlayerIds(player_ids)
        const opts = await EngageApi.getCompetitionOptions();
        const event_ids = EngageHelpers.getEventIdsFromSquares(squares);
        const evts = await EngageApi.getEventsByEventIds(event_ids);
        const bracket_ids = EngageHelpers.getBracketIdsFromBracketComps(brackets);
        const bcks = await EngageApi.getBracketsByIds(bracket_ids)

        setModuleData({ 
            ...module_data,
            loading:false,
            competitions:comps,
            competition_seasons: seasons,
            bracket_competitions:brackets,
            competition_types: opts.competition_types,
            competition_result_types: opts.competition_result_types,
            competition_payouts: opts.competition_payout_types,
            squares_competitions: squares,
            companies: cpanys,
            events: evts,
            brackets: bcks,
            leagues: lgs,
            players: plyers
        })
    }


    const renderSquaresCompetitions = (data: { item:SquaresCompetitionProps, index:number }) => {
        const admin = players.find(p => p.player_id == data.item.admin_id);
        const company = companies.find(c => c.company_id == data.item.company_id);
        const event = events.find(e => e.event_id == data.item.event_id)
        return (
            <View>
                <SquaresCompetitionCard
                    squares_competition={data.item}
                    company={company}
                    admin={admin}
                    event={event}
                    onSelectCompetition={(sc) => onSelectSquaresCompetition(sc)}
                />
            </View>
        )
    }


    const renderBracketCompetitions = (data: { item:BracketCompetitionProps, index:number }) => {
        const admin = players.find(p => p.player_id == data.item.admin_id);
        const company = companies.find(c => c.company_id == data.item.company_id);
        const competition_result_type = competition_result_types.find(t => t.competition_result_type_id == data.item.competition_result_type_id);
        const bracket = brackets.find(b => b.bracket_id == data.item.bracket_id);
        const league = leagues.find(e => e.league_id == bracket?.league_id)
        if(!competition_result_type){ return <></> }
        return (
            <View>
                <BracketCompetitionCard
                    bracket_competition={data.item}
                    company={company}
                    competition_result_type={competition_result_type}
                    admin={admin}
                    bracket={bracket}
                    league={league}
                    onCompetitionSelect={(sc) => onSelectBracketCompetition(sc)}
                />
            </View>
        )
    }
    const renderSeasons = (data: { item:CompetitionSeasonProps, index:number }) => {
        return (
            <View>
                <SeasonCard
                    competition_season={data.item}
                    width={module_size.width / 2.5}
                    onSelectSeason={(cs) => onSelectCompetitionSeason(cs)}
                />
            </View>
        )
    }

    const renderCompetitions = (data: { item:CompetitionProps, index:number }) => {
        const admin = players.find(p => p.player_id == data.item.admin_id);
        const pacer = players.find(p => p.player_id == data.item.pacer_id);
        const company = companies.find(c => c.company_id == data.item.company_id)
        const competition_type = competition_types.find(t => t.competition_type_id == data.item.competition_type_id);
        const competition_result_type = competition_result_types.find(t => t.competition_result_type_id == data.item.competition_result_type_id);
        if(!competition_type || !competition_result_type){ return <></> }
        return (
            <View>
                <CompetitionCard 
                    competition={data.item}
                    company={company}
                    competition_type={competition_type}
                    competition_result_type={competition_result_type}
                    admin={admin}
                    pacer={pacer}
                    onCompetitionSelect={(c) => onSelectCompetition(c)}
                />
            </View>
        )
    }

    if(loading){
        return (
            <View style={{flex:1, backgroundColor:Colors.shades.white}}>
                <ActivityIndicator style={{ padding:20, alignSelf:'center' }} size='large' color={Colors.brand.midnight} />
            </View>
        )
    }

    return (
        <View style={{ flex:1, backgroundColor:Colors.shades.white }} >
            <ScrollView style={{ flex:1 }} onLayout={(ev) => {
                const { height, width } = ev.nativeEvent.layout;
                setModuleSize({ width, height })
            }}>
                <EngageHeader
                    companies={companies}
                    width={module_size.width}
                    onManage={onManage}
                />
                <View style={{ ...view_styles.body_row, margin:10, backgroundColor:Colors.shades.white, borderRadius:22, borderWidth:4, borderColor:Colors.shades.shade100}}>
                    <Button
                        title='COMPETITIONS'
                        title_color={active_tab == 'competitions' ? Colors.shades.white : Colors.brand.midnight}
                        title_weight={active_tab == 'competitions' ? 'bold' : 'regular'}
                        padding={15}
                        title_size={12}
                        style={{flex:1}}
                        borderRadiusOverride={{
                            borderTopLeftRadius: 22,
                            borderBottomLeftRadius:22,
                            borderTopRightRadius:0,
                            borderBottomRightRadius:0
                        }}
                        backgroundColor={active_tab == 'competitions' ? Colors.brand.midnight : Colors.shades.white}
                        onPress={() => setActiveTab('competitions')}
                    />
                    <Button
                        title='SQUARES'
                        title_size={12}
                        title_color={active_tab == 'squares' ? Colors.shades.white : Colors.brand.midnight}
                        title_weight={active_tab == 'squares' ? 'bold' : 'regular'}
                        padding={15}
                        style={{flex:1}}
                        borderRadius={0}
                        backgroundColor={active_tab == 'squares' ? Colors.brand.midnight : Colors.shades.white}
                        onPress={() => setActiveTab('squares')}
                    />
                    <Button
                        title='BRACKETS'
                        style={{flex:1}}
                        title_size={12}
                        title_color={active_tab == 'brackets' ? Colors.shades.white : Colors.brand.midnight}
                        title_weight={active_tab == 'brackets' ? 'bold' : 'regular'}
                        padding={15}
                        borderRadiusOverride={{
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius:0,
                            borderTopRightRadius:22,
                            borderBottomRightRadius:22
                        }}
                        backgroundColor={active_tab == 'brackets' ? Colors.brand.midnight : Colors.shades.white}
                        onPress={() => setActiveTab('brackets')}
                    />
                </View>
                <View style={{ ...view_styles.section_header, backgroundColor:Colors.shades.shade600, borderRadius:0, marginBottom:10 }}>
                    <Icons.EyeOffIcon size={18} color={Colors.brand.midnight} />
                    <View style={{ flex:1, marginLeft:10 }}>
                        <Text theme='header'>Find Private</Text>
                        <Text style={{ marginTop:3 }} theme='body'>Did you receive a private code? Enter it here.</Text>
                    </View>
                    <Button
                        title='ENTER PRIVATE CODE'
                        padding={15}
                        title_color={Colors.shades.white}
                        backgroundColor={Colors.utility.success}
                        onPress={() => setShowCodePrompt(true)}
                    />
                </View>
                {active_tab == 'competitions' ?
                <View style={{ ...view_styles.section_body, justifyContent:'flex-start', padding:0 }}>
                    {competition_seasons.length > 0 ?
                    <View style={{ marginTop:10 }}>
                        <View style={{ ...view_styles.section_header, backgroundColor:Colors.shades.shade100, borderRadius:0, marginBottom:10 }}>
                            <View style={{ flex:1 }}>
                                <Text theme='header'>Public Seasons</Text>
                                <Text style={{ marginTop:3 }} theme='body'>Seasons are multi-competition contests. Payouts are split between each competition and the season long winners.</Text>
                            </View>
                            {onCreateSeason ?
                            <Button
                                title='NEW'
                                title_color={Colors.shades.white}
                                backgroundColor={Colors.utility.success}
                                onPress={() => onCreateSeason()}
                            />
                            :<></>}
                        </View>
                        <FlatList
                            data={competition_seasons.sort((a,b) => moment(a.scheduled_datetime).unix() - moment(b.scheduled_datetime).unix())}
                            horizontal
                            keyExtractor={(item) => item.competition_season_id.toString()}
                            renderItem={renderSeasons}
                        />
                    </View>
                    :<></>}
                    <View style={{ marginBottom:15, marginTop:10 }}>
                        <View style={{ ...view_styles.section_header, backgroundColor:Colors.shades.shade100, borderRadius:0 }}>
                            <View style={{ flex:1 }}>
                                <Text theme='header'>Public Competitions</Text>
                                <Text style={{ marginTop:3 }} theme='body'>Below are available pick-em and wager based competitions.  All competitions are offered with 0 fees. All ticket sales are paid out to the winners!</Text>
                            </View>
                            {onCreateCompetition ?
                            <Button
                                title='NEW'
                                title_color={Colors.shades.white}
                                backgroundColor={Colors.utility.success}
                                onPress={() => onCreateCompetition()}
                            />
                            :<></>}
                        </View>
                    </View>
                    <FlatList
                        data={competitions.sort((a,b) => moment(a.scheduled_datetime).unix() - moment(b.scheduled_datetime).unix())}
                        renderItem={renderCompetitions}
                        keyExtractor={(item) => item.competition_id.toString()}
                    />
                </View>
                :active_tab == 'squares' ?
                <View style={{ ...view_styles.section_body, padding:0 }}>
                    <View style={{ marginBottom:15, marginTop:10 }}>
                        <View style={{ ...view_styles.section_header, backgroundColor:Colors.shades.shade100, borderRadius:0 }}>
                            <View style={{ flex:1 }}>
                                <Text theme='header'>Public Auction Squares</Text>
                                <Text style={{ marginTop:3 }} theme='body'>Below are available squares competitions.  Bid on squares until the auction period ends.  Win $ and prizes when your square hits at the end of each quarter!</Text>
                            </View>
                            {onCreateSquares ?
                            <Button
                                title='NEW'
                                title_color={Colors.shades.white}
                                backgroundColor={Colors.utility.success}
                                onPress={() => onCreateSquares()}
                            />
                            :<></>}
                        </View>
                    </View>
                    <FlatList
                        data={squares_competitions.sort((a,b) => moment(a.begin_datetime).unix() - moment(b.begin_datetime).unix())}
                        renderItem={renderSquaresCompetitions}
                        keyExtractor={(item) => item.sq_comp_id.toString()}
                    />
                </View>
                :active_tab == 'brackets' ?
                <View style={{ ...view_styles.section_body, padding:0 }}>
                    <View style={{ marginBottom:15, marginTop:10 }}>
                        <View style={{ ...view_styles.section_header, backgroundColor:Colors.shades.shade100, borderRadius:0 }}>
                            <View style={{ flex:1 }}>
                                <Text theme='header'>Public Bracket Competitions</Text>
                                <Text style={{ marginTop:3 }} theme='body'>Below are available bracket competitions.  All competitions are offered with 0 fees. All ticket sales are paid out to the winners!</Text>
                            </View>
                            {onCreateBracketCompetition ?
                            <Button
                                title='NEW'
                                title_color={Colors.shades.white}
                                backgroundColor={Colors.utility.success}
                                onPress={() => onCreateBracketCompetition()}
                            />
                            :<></>}
                        </View>
                    </View>
                    <FlatList
                        data={bracket_competitions.sort((a,b) => moment(a.scheduled_datetime).unix() - moment(b.scheduled_datetime).unix())}
                        renderItem={renderBracketCompetitions}
                        keyExtractor={(item) => item.bracket_competition_id.toString()}
                    />
                </View>
                :<></>}
            </ScrollView>
            {show_code_prompt ?
            <View style={{ position:'absolute', top:0, left:0, right:0, bottom:0, justifyContent:'flex-end', backgroundColor:Colors.shades.black_faded_heavy }}>
                <PrivateCodePrompt
                    width={module_size.width - 20}
                    onFoundBracket={(b) => {
                        onSelectBracketCompetition(b)
                        setShowCodePrompt(false);
                    }}
                    onFoundCompetition={(c) => {
                        onSelectCompetition(c)
                        setShowCodePrompt(false);

                    }}
                    onFoundSeason={(s) => {
                        onSelectCompetitionSeason(s)
                        setShowCodePrompt(false);
                    }} 
                    onFoundSquaresCompetition={(sc) => {
                        onSelectSquaresCompetition(sc)
                        setShowCodePrompt(false);
                    }}
                    onClose={() => setShowCodePrompt(false)}
                />
            </View>
            :<></>}

            <SocketManager
                onConnect={() => setSocketState({ ...socket_state, connected: true })}
                subscribed_events={[]}
                onSocketEvent={() => { return }}
                onDisconnect={() => setSocketState({ ...socket_state, connected: false, reload_needed: true })}
            />
        </View>
    )
}

export default EngageModule