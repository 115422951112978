import React from 'react';
import { FlatList, TouchableOpacity, View } from "react-native"
import type { AthleteProps, CompetitionMatchMarketProps, CompetitionMatchProps, CompetitionPlayerProps, CompetitionProps, CompetitionTypeProps, LeagueProps, MarketProps, PlayerPickProps } from "../../types";
import Colors from '../../constants/colors';
import { useEffect, useState } from "react"
import TeamEventCard from './TeamEventCard';
import AthleteMarketCard from './AthleteMarketCard';
import MatchMarketCard from './MatchMarketCard';
import type { EventProps, MatchProps, TournamentProps } from "../../types";
import moment from "moment-mini";
import { view_styles } from "../../constants/styles";
import LinearGradient from "react-native-linear-gradient";
import { Icons, Text } from "../../Components";
import LinearDiagnal from "../../Components/LinearDiagnal";
import SpringView from "../../Components/Spring";
import type { BEEventProps } from "../../Observer/api/types";

type CompetitionPlayProps = {
    player_id?:string,
    authenticated_competition_player?:CompetitionPlayerProps
    competition:CompetitionProps,
    competition_type:CompetitionTypeProps,
    competition_players:CompetitionPlayerProps[],
    competition_matches:CompetitionMatchProps[],
    competition_match_markets:CompetitionMatchMarketProps[],
    leagues:LeagueProps[],
    athletes:AthleteProps[],
    events:EventProps[],
    tournaments:TournamentProps[],
    matches:MatchProps[],
    markets:MarketProps[],
    player_picks:PlayerPickProps[],
    onEvent?:(event:BEEventProps) => void,
    height:number,
    width:number,
    pick_loading?:string,
    source?:string,
    onPick:(pp:PlayerPickProps, event_title:string, event_start:string) => void,
    onGoBack?:() => void,
    hide_player?:boolean,
    onRequestAuthentication: () => void,
    back_allowed?:boolean
    onChangeCompetition?: (competition_id:string) => void
}
const CompetitionPlay = ( { authenticated_competition_player, pick_loading, competition, competition_type, onPick, competition_matches, competition_match_markets, leagues, athletes, events, tournaments, matches, markets, player_id, player_picks }:CompetitionPlayProps ) => {
    //const [ complete_loaded, setCompleteLoaded ] = useState(false);
    const [ contest_view, setContestView ] = useState<'upcoming'|'closed'>('upcoming')
    //const [ show_completed, setShowCompleted ] = useState(false);
    const [ expanded_markets, setExpandedMarkets ] = useState<string[]>([]);
    const [ expanded, setExpanded ] = useState(true);


    const ip_unique_team_events = [ ... new Set(competition_matches.filter(cm => cm.event_type == 'team' && cm.status == 'inprogress').map(cm => cm.event_id)) ]
    let team_event_ids = events.filter(e => ip_unique_team_events.includes(e.event_id)).sort((a,b) => moment(a.scheduled_datetime).unix() - moment(b.scheduled_datetime).unix()).map(e => e.event_id)

    const closed_unique_team_events = [ ... new Set(competition_matches.filter(cm => cm.event_type == 'team' && cm.status == 'closed').map(cm => cm.event_id)) ]
    if(contest_view == 'closed'){
        team_event_ids = events.filter(e => closed_unique_team_events.includes(e.event_id)).sort((a,b) => moment(a.scheduled_datetime).unix() - moment(b.scheduled_datetime).unix()).map(e => e.event_id)
    }


    const unique_tournaments = [ ... new Set(Object.values(matches).map(m => m.tournament_id))]


    const picks_completed = player_picks.length == competition.max_pick_count ? true : false


    useEffect(() => {
        if(!player_id){ return }
        if(!competition_type || !competition){ return }
        if(competition_type.type == 'pick'){
            //Need to determine if we need to show complete label!
            if(player_picks.length == competition.max_pick_count){
                //alert('Competition is complete!')
            }
        }
    },[player_picks.length])


    const renderAthleteEvents = (data: { item:CompetitionMatchProps, index:number }) => {
        const event = events.find(e => e.event_id == data.item.event_id);
        if(!event || !competition || !competition_type){ return <></> }
        const athlete = athletes.find(a => a.athlete_id == data.item.side_id_override);
        if(!athlete){ return <></> }
        const match_markets = competition_match_markets.filter(cmm => cmm.competition_match_id == data.item.competition_match_id)
        const event_picks = player_picks.filter(pp => pp.competition_match_id == data.item.competition_match_id)
        const isLoading = pick_loading == data.item.competition_match_id ? true : false
        if(match_markets.length != 2){ return <></> }
        return (
            <View style={{ minWidth:150, flex:1, flexGrow:1, margin:5, borderWidth:1, borderColor:Colors.shades.shade600, borderRadius:8, backgroundColor:Colors.shades.white, ...view_styles.float }}>
                <AthleteMarketCard
                    event={event}
                    athlete={athlete}
                    competition={competition}
                    competition_type={competition_type}
                    markets={markets}
                    competition_match={data.item}
                    mode='play'
                    orientation="vertical"
                    onPick={(pp, event_title, event_start) => onPick(pp, event_title, event_start)}
                    competition_match_markets={match_markets}
                    player_picks={event_picks}
                    loading={isLoading}
                />
            </View>
        )
    }

    const renderEvents = (data:{ item:CompetitionMatchProps, index:number }) => {
        const event = events.find(e => e.event_id == data.item.event_id);
        if(!event || !competition || !competition_type){ return <></> }
        const match_markets = competition_match_markets.filter(cmm => cmm.competition_match_id == data.item.competition_match_id);
        const event_picks = player_picks.filter(pp => pp.competition_match_id == data.item.competition_match_id);
        const isLoading = pick_loading == data.item.competition_match_id ? true : false
        return (
            <View style={{ flex:1, flexGrow:1, margin:5 }}>
                <TeamEventCard
                    event={event}
                    markets={markets}
                    competition={competition}
                    competition_type={competition_type}
                    player_picks={event_picks}
                    mode='play'
                    loading={isLoading}
                    onPick={(pp, event_title, event_start) => onPick(pp, event_title, event_start)}
                    competition_match={data.item}
                    competition_match_markets={match_markets}
                />
            </View>
        )
    }

    const renderNonPrimaryMarkets = (data:{ item:string, index: number, event_id:string, event_type:string }) => {
        const market = markets.find(m => m.market_id == data.item);
        if(!market){ return <></> }

        const athlete_non_primary_matches = competition_matches.filter(cm => cm.market_id_override == data.item && cm.side_type_override == 'athlete' && cm.event_id == data.event_id && cm.event_type == data.event_type);

        return (
            <View>
                <LinearDiagnal label_size={12} label={market.description} left_color={Colors.brand.midnight} right_color={Colors.brand.midnightTopGradient}/>
                <View style={{ flexDirection:'row', flexWrap:'wrap', justifyContent:'center', paddingTop:10, paddingBottom:10 }}>
                    {athlete_non_primary_matches.map((cm,i) => {
                        return renderAthleteEvents({ item: cm, index:i })
                    })}
                </View>
            </View>
        )
    }
    
    const renderMatchEvents = (data: { item: CompetitionMatchProps, index:number }) => {
        const match = matches.find(m => m.match_id == data.item.event_id);
        if(!match || !competition || !competition_type){ return <></> }
        const away_athlete = athletes.find(a => a.athlete_id == match.participants[0])
        const home_athlete = athletes.find(a => a.athlete_id == match.participants[1])
        const match_markets = competition_match_markets.filter(cmm => cmm.competition_match_id == data.item.competition_match_id)
        const event_picks = player_picks.filter(pp => pp.competition_match_id == data.item.competition_match_id)
        const isLoading = pick_loading == data.item.competition_match_id ? true : false

        return (
            <View style={{ minWidth:200, flex:1, flexGrow:1, margin:5, borderWidth:1, borderColor:Colors.shades.shade600, borderRadius:8, backgroundColor:Colors.shades.white, ...view_styles.float }}>
                <MatchMarketCard
                    competition={competition}
                    competition_type={competition_type}
                    match={match}
                    away_athlete={away_athlete}
                    home_athlete={home_athlete}
                    markets={markets}
                    competition_match={data.item}
                    competition_match_markets={match_markets}
                    player_picks={event_picks}
                    loading={isLoading}
                    onPick={(pp, event_title, event_start) => onPick(pp, event_title, event_start)}
                    mode='play'
                />
            </View>
        )
    }


    const renderTournaments = (data: {item:string, index:number}) => {
        const tournament = tournaments.find(t => t.tournament_id == data.item)
        if(!tournament){ return <></> } 
        const tournament_matches = Object.values(matches).filter(m => m.tournament_id == data.item).sort((a,b) => moment(a.scheduled_datetime).unix() - moment(b.scheduled_datetime).unix())
        if(tournament_matches.length == 0){ return <></> }

        //Now lets get all the competition_matches that include these match ids
        const match_matches = competition_matches.filter(cm => cm.event_type == 'match' && tournament_matches.map(tm => tm.match_id.toString()).includes(cm.event_id).toString())

        const league = leagues.find(l => l.league_id == tournament.league_id);
        //const expanded = expanded_markets.includes(data.item) ? true : false
        return (
            <LinearGradient start={{x: 0, y: 0}} end={{x: 1, y: 0}}  colors={[Colors.shades.white, '#F8F8F8']} style={{ minWidth:350, flex:1, flexGrow:1, margin:10, borderWidth:1, borderColor:Colors.shades.shade100, borderRadius:8, ...view_styles.float }}>
                <View style={{ padding:10, flexDirection:'row', borderBottomWidth:1, borderColor:Colors.shades.shade600 }}>
                    {league ?
                    <Text size={14} color={Colors.brand.midnight} weight='bold'>{league.league_name}: </Text>
                    :<></>}
                    <Text style={{ flex:1 }} size={14} color={Colors.brand.midnight} weight='bold'>{tournament.tournament_name}</Text>
                    <Text size={14} color={Colors.brand.midnight} weight='regular'>{tournament.scheduled_datetime == 'scheduled' ? moment(tournament.scheduled_datetime).format('MM/DD @ hh:mm a'): tournament.time_detail}</Text>
                </View>
                
                <LinearDiagnal label_size={12} label="Matches" left_color={Colors.brand.midnight} right_color={Colors.brand.midnightTopGradient}/>
                <View style={{ flexDirection:'row', flexWrap:'wrap', justifyContent:'center', paddingTop:10, paddingBottom:10 }}>
                    {match_matches.map((cm, i) => {
                        return renderMatchEvents({ item: cm, index: i })
                    })}
                </View>
            </LinearGradient>
        )
    }

    const renderTeamEvents = (data: {item:string, index:number}) => {
        const event = events.find(e => e.event_id == data.item)//[data.item]
        if(!event){ return <></> } 
        const league = leagues.find(l => l.league_id == event.league_id);
        const team_primary_matches = competition_matches.filter(cm => cm.event_type == 'team' && cm.event_id == data.item && !cm.market_id_override);
        const non_primary_markets = [ ... new Set(competition_matches.filter(cm => cm.event_type == 'team' && cm.event_id == data.item && cm.market_id_override).map(cm => cm.market_id_override ?? ''))]
        const expanded = expanded_markets.includes(data.item) ? true : false
        return (
            <LinearGradient start={{x: 0, y: 0}} end={{x: 1, y: 0}}  colors={[Colors.shades.white, '#F8F8F8']} style={{ minWidth:350, flex:1, flexGrow:1, margin:10, borderWidth:1, borderColor:Colors.shades.shade100, borderRadius:8, ...view_styles.float }}>
                <View style={{ padding:10, flexDirection:'row', borderBottomWidth:1, borderColor:Colors.shades.shade600 }}>
                    {league ?
                    <Text size={14} color={Colors.brand.midnight} weight='bold'>{league.league_name}: </Text>
                    :<></>}
                    <Text style={{ flex:1 }} size={14} color={Colors.brand.midnight} weight='bold'>{event.event_title}</Text>
                    <Text size={14} color={Colors.brand.midnight} weight='regular'>{event.time_detail == 'scheduled' ? moment(event.scheduled_datetime).format('MM/DD @ hh:mm a'): event.time_detail}</Text>
                </View>
                
                <LinearDiagnal label_size={12} label="Primary Markets" left_color={Colors.brand.midnight} right_color={Colors.brand.midnightTopGradient}/>
                {team_primary_matches.map((cm, i) => {
                    return renderEvents({ item: cm, index: i })
                })}
                {non_primary_markets.length > 0 ?
                <View style={{ borderBottomRightRadius:8, borderBottomLeftRadius:8 }}>
                    {expanded ?
                    <View>
                        {non_primary_markets.map((id, i) => {
                            return renderNonPrimaryMarkets({ item:id, index:i, event_id: data.item, event_type: 'team' })
                        })}
                    </View>
                    :<></>}
                     <TouchableOpacity style={{ flexDirection:'row', alignItems:'center', padding:15, borderTopWidth:1, borderColor:Colors.shades.shade600 }} onPress={() => expanded ? setExpandedMarkets(expanded_markets.filter(m => m != data.item)): setExpandedMarkets(expanded_markets.concat(data.item))}>
                        <Text style={{ flex:1 }} size={14} color={Colors.brand.midnight} weight='semibold'>{expanded?'Hide Markets':`${non_primary_markets.length} More Markets` }</Text>
                        <Icons.ChevronIcon direction={expanded?'up':'down'} color={Colors.brand.midnight} size={8}/>
                    </TouchableOpacity>
                </View>
                :<></>}
            </LinearGradient>
        )
    }



    return (
        <View style={{ ...view_styles.section, minWidth:350 }}>
                <TouchableOpacity style={view_styles.section_header} onPress={() => setExpanded(!expanded)}>
                    <Icons.SelectorIcon size={18} color={Colors.brand.midnight} />
                    <View style={{ flex:1, marginLeft:10 }}>
                        <Text theme="header">MANAGE MY {competition_type.type == 'pick' ? 'PICKS' : 'WAGERS'}</Text>
                        <Text style={{ marginTop: 4 }} theme="body_2">Make {competition_type.type == 'pick' ? 'Picks' : 'Wagers'} from the available contests.</Text>
                    </View>
                    <View style={{marginLeft:8, marginRight:8, borderRadius:100, height:24, width:24, backgroundColor:picks_completed ? Colors.utility.success : Colors.highlights.highlight200, justifyContent:'center', alignItems:'center'}}>
                        <Text size={12} color={Colors.shades.white} weight='bold' textAlign='center'>{player_picks.filter(pp => pp.status != 'deleted').length}</Text>
                    </View>
                    <Icons.ChevronIcon direction={expanded?'up':'down'} color={Colors.brand.midnight} size={8} />
                </TouchableOpacity>
                {expanded ?
                <View style={{ ...view_styles.section_body, padding:0 }}>
                    <View style={{ flexDirection:'row', backgroundColor:Colors.shades.white, margin:5, marginTop:15, borderRadius:22, borderWidth:4, borderColor:Colors.shades.shade100 }}>
                        <TouchableOpacity
                            style={{ flex:1, flexDirection:'row', alignItems:'center', borderRadius:22, padding:10, backgroundColor: contest_view=='upcoming'?Colors.brand.midnight:Colors.shades.white }}
                            onPress={() => setContestView('upcoming')}
                        >
                            <Text style={{ flex:1 }} size={12} color={contest_view == 'upcoming'?Colors.shades.white:Colors.brand.midnight} weight={contest_view == 'upcoming' ? 'bold': 'semibold'}>UPCOMING CONTESTS</Text>
                            <View style={{ borderRadius:100, justifyContent:'center', alignItems:'center', height:25, width:25, backgroundColor:contest_view == 'upcoming' ? Colors.shades.white : Colors.brand.midnight }}>
                                <Text weight='bold' size={12} color={contest_view == 'upcoming' ? Colors.brand.midnight: Colors.shades.white}>{ip_unique_team_events.length}</Text>
                            </View>
                        </TouchableOpacity>
                        <TouchableOpacity
                            style={{ flex:1, flexDirection:'row', alignItems:'center', borderRadius:22, padding:10, backgroundColor: contest_view=='closed'?Colors.brand.midnight:Colors.shades.white }}
                            onPress={() => setContestView('closed')}
                        >
                            <Text style={{  flex:1 }} size={12} color={contest_view == 'closed'?Colors.shades.white:Colors.brand.midnight} weight={contest_view == 'closed' ? 'bold': 'semibold'}>CLOSED CONTESTS</Text>
                            <View style={{ borderRadius:100, justifyContent:'center', alignItems:'center', height:25, width:25, backgroundColor:contest_view == 'closed' ? Colors.shades.white : Colors.brand.midnight }}>
                                <Text weight='bold' size={12} color={contest_view == 'closed' ? Colors.brand.midnight: Colors.shades.white}>{closed_unique_team_events.length}</Text>
                            </View>
                        </TouchableOpacity>

                    </View>
                    <View nativeID="picks" style={{ marginTop:15 }}>
                        <View style={{flexDirection:'row', alignItems:'center', justifyContent:'space-between', marginBottom:15}} >
                            <View style={{ flex:1, flexDirection:'row', alignItems:'center', backgroundColor:Colors.shades.shade100 }}>
                                    <View style={{ flex:1, flexDirection:'row', alignItems:'center', padding:10 }}>
                                        <Text size={16} color={Colors.brand.midnight} weight='bold'>{contest_view.toUpperCase()} CONTESTS</Text>
                                        
                                    </View>
                                    {authenticated_competition_player ?
                                    <SpringView 
                                        slide="horizontal"
                                        from={200}
                                        to={0}
                                        style={{ flex:1, flexDirection:'row', borderTopLeftRadius:22, borderBottomLeftRadius:22, padding:10, alignItems:'center', backgroundColor:Colors.utility.success, ...view_styles.float }}
                                        >
                                            { competition_type?.type == 'wager' ?
                                            <Text style={{ flex:1 }} size={14} color={Colors.shades.white} weight='semibold' textAlign="right">{authenticated_competition_player.wager_based_balance.toFixed(2)} Remaining</Text>
                                            :
                                            <Text style={{ flex:1 }} size={14} color={Colors.shades.white} weight='semibold' textAlign="right">{parseFloat(competition.max_pick_count as string) - player_picks.length} Picks Remaining</Text>
                                            }
                                    </SpringView>
                                    :<></>}
                            </View>
                        </View>
                        <FlatList
                            data={team_event_ids}
                            renderItem={renderTeamEvents}
                            keyExtractor={(item) => item}
                        />
                        <FlatList
                            data={unique_tournaments}
                            renderItem={renderTournaments}
                            keyExtractor={(item) => item}
                        />
                    </View>
                </View>
                :<></>}
                
        </View>
    )
}

export default CompetitionPlay