import { FlatList, Image, ScrollView, TouchableOpacity, View } from "react-native"
import React, { useEffect, useState } from "react"
import { PollCampaignApi } from "../api"
import type { PollCampaignLeaderProps, PollCampaignProps, PollProps, PollResponseProps, PublicPlayerProps } from "../../types"
import { Button, Icons, Text } from "../../Components"
import Colors from '../../constants/colors';

type CampaignResultProps = {
    poll_campaign:PollCampaignProps,
    polls:PollProps[],
    onShare?:() => void,
    hide_back?:boolean,
    poll_responses:PollResponseProps[],
    onClose: () => void
}
const CampaignResult = ({ poll_campaign, polls, poll_responses, hide_back, onClose, onShare } : CampaignResultProps) => {
    const [ leaders_state, setLeadersState ] = useState<{
        loading:boolean,
        offset:number,
        players:PublicPlayerProps[]
        poll_campaign_leaders:PollCampaignLeaderProps[]
    }>({ 
        loading:false, 
        offset:0,
        poll_campaign_leaders:[],
        players: []
    });
    const [ points_expanded, setPointsExpanded ] = useState(false);

    const { players, poll_campaign_leaders } = leaders_state;

    useEffect(() => {
        getDataFromServer(poll_campaign.poll_campaign_id, 0)
    },[poll_campaign.poll_campaign_id])

    const getDataFromServer = async(poll_campaign_id:string, offset:number) => {
        setLeadersState({ ...leaders_state, loading:true })
        const leaders = await PollCampaignApi.getLeadersByCampaignId(poll_campaign_id, offset)
        const player_ids = leaders.poll_campaign_leaders.map(l => l.player_id);
        const ps = await PollCampaignApi.getPlayersByPlayerIds(player_ids);
        setLeadersState({ 
            loading:false,
            poll_campaign_leaders: leaders.poll_campaign_leaders,
            players: ps,
            offset
        })
    }

    const renderTrophy = (place:number) => {
        switch(place){
            case 1: return <Icons.TrophyIcon color={Colors.incentive.gold} size={16}/>
            case 2: return <Icons.TrophyIcon color={Colors.incentive.silver} size={16}/>
            case 3: return <Icons.TrophyIcon color={Colors.incentive.bronze} size={16}/>
            default: return <></>
        }
    }

    const renderLeaders = (data: {item:PollCampaignLeaderProps, index:number}) => {
        const player = players.find(p => p.player_id == data.item.player_id);
        if(!player){ return <></> }
        return (
            <View style={{ flexDirection:'row', alignItems:'center', margin:4, padding:10, borderRadius:22, backgroundColor:Colors.shades.white }}>
                <View nativeID="place" style={{ margin:5, height:20, width:20, justifyContent:'center', alignItems:'center', borderRadius:100, borderWidth:1, borderColor:Colors.brand.slate }}>
                    <Text size={10} color={Colors.brand.midnight} weight='bold' textAlign="center">{data.index+1}</Text>
                </View>
                <View nativeID="player" style={{ flex:1, flexDirection:'row', alignItems:'center' }}>
                    <View nativeID="profile_pic" style={{ marginRight:10 }}>
                        <Image
                            source={{ uri: player.profile_pic && player.profile_pic != '' ? player.profile_pic : 'https://res.cloudinary.com/hoabts6mc/image/upload/v1689262384/default-avatar_bbkn2t.png' }}
                            style={{ height:40, width:40, borderRadius:100 }}
                            resizeMode='cover'
                        />
                    </View>
                    <View style={{ flex:1 }} nativeID="name">
                        <Text size={14} color={Colors.brand.midnight} weight='bold'>{player.username}</Text>
                        <Text style={{ marginTop:3 }} size={12} color={Colors.brand.midnight} weight='regular'>{data.item.winnings.toFixed(2)} Points</Text>
                    </View>
                    <View>
                        {renderTrophy(data.index + 1)}
                    </View>
                </View>
            </View>
        )
    }


    //const questions_answered = poll_responses.length
    let correct_answers = poll_responses.filter(pr => pr.result_ind == 'win').length
    //let incorrect_answers = poll_responses.filter(pr => pr.result_ind == 'lose').length
    let ungraded_answers = poll_responses.filter(pr => !pr.result_ind).length
    let points_earned = poll_responses.reduce((a,b) => a + b.winnings, 0)

    return (
        <View style={{ flex:1 }}>
            <ScrollView style={{ flex:1, padding:10, marginBottom:10 }}>
                <TouchableOpacity 
                    style={{ flexDirection:'row', padding:10, paddingRight:15, paddingLeft:15, borderRadius:22, justifyContent:'center', alignItems:'center', marginBottom:10, backgroundColor:Colors.incentive.gold }}
                    onPress={() => {
                        if(onShare){ onShare() }
                    }}
                    >
                    <Icons.ShareSocialIcon color={Colors.shades.white} size={14}/>
                    <Text style={{ marginLeft:5 }} size={14} color={Colors.shades.white} weight='bold'>Share your results for a chance at a prize!</Text>
                </TouchableOpacity>
                <View nativeID="my_results">
                    <Text style={{ margin:10 }} size={16} color={Colors.brand.midnight} weight='bold'>My Results</Text>
                    <View style={{borderRadius:22, backgroundColor:Colors.shades.shade100, padding:20}}>
                        <View nativeID="completion" style={{ flexDirection: 'row' }}>
                            <View nativeID="total_questions" style={{ flex:1 }}>
                                <Text size={12} color={Colors.brand.slate} weight='semibold'>TOTAL QUESTIONS</Text>
                                <Text style={{ marginTop:5 }} size={16} color={Colors.brand.midnight} weight='bold'>{polls.length} Questions</Text>
                            </View>
                            <View nativeID="completion_pct" style={{ flex:1 }}>
                                <Text size={12} color={Colors.brand.slate} weight='semibold'>COMPLETION</Text>
                                <Text style={{ marginTop:5 }} size={16} color={Colors.brand.midnight} weight='bold'>{(((polls.length - ungraded_answers) / polls.length) * 100).toFixed()}%</Text>
                            </View>
                        </View>
                        <View nativeID="performance" style={{ flexDirection: 'row', marginTop:10 }}>
                            <View nativeID="total_questions" style={{ flex:1 }}>
                                <Text size={12} color={Colors.brand.slate} weight='semibold'>SUCCESS RATE</Text>
                                <Text style={{ marginTop:5 }} size={16} color={Colors.brand.midnight} weight='bold'>{((correct_answers / polls.length)*100).toFixed()}%</Text>
                            </View>
                            <View nativeID="completion_pct" style={{ flex:1 }}>
                                <Text size={12} color={Colors.brand.slate} weight='semibold'>POINTS EARNED</Text>
                                <Text style={{ marginTop:5 }} size={16} color={Colors.brand.midnight} weight='bold'>{points_earned.toFixed()} Points</Text>
                            </View>
                        </View>
                        <View style={{ borderRadius:22, backgroundColor:Colors.highlights.highlight200Faded, padding:10, marginTop:10 }}>
                            <TouchableOpacity style={{ flexDirection:'row', alignItems:'center' }} onPress={() => setPointsExpanded(!points_expanded)}>
                                <View style={{ flexDirection:'row', alignItems:'center', flex:1 }}>
                                    <Icons.AlertIcon color={Colors.brand.midnight} size={14}/>
                                    <Text style={{ marginLeft:5 }} size={14} color={Colors.brand.midnight} weight='semibold'>How are points calculated?</Text>
                                </View>
                                <Icons.ChevronIcon direction={points_expanded ? 'up' : 'down'} color={Colors.brand.midnight}/>
                            </TouchableOpacity>
                            {points_expanded ?
                            <>
                                <Text size={12} color={Colors.brand.midnight} style={{ marginTop:10 }} weight='regular'>Points are calculated based on the total responses by all participants.  Each response is worth 1 point.  The responders that selected the winning option will split the total point pool.</Text>
                                <Text size={12} color={Colors.brand.midnight} style={{ marginTop:5 }} weight='regular'>Therefore, points earned will not be updated until the question is closed!</Text>
                            </>
                            :<></>}
                        </View>
                    </View>
                </View>
                <View nativeID="leader_header" style={{ margin:10, flexDirection:'row', alignItems:'center' }}>
                    <Text style={{ flex:1 }} size={16} color={Colors.brand.midnight} weight='bold'>Leaderboard</Text>
                    <View style={{ borderRadius:22, padding:10, backgroundColor:Colors.accents.accent100 }}>
                        <Text size={14} color={Colors.brand.midnight} weight='regular' textAlign="center">{poll_campaign.total_responders} Participants</Text>
                    </View>
                </View>
                <View nativeID="leaderboard" style={{ flex:1, borderTopRightRadius:22, borderTopLeftRadius:22, padding:10, backgroundColor:Colors.shades.shade100}}>
                    {poll_campaign.status == 'active' ?
                    <Text size={12} color={Colors.utility.warning} textAlign="center">Points change as new responses come in</Text>
                    :<></>}
                    <FlatList
                        data={poll_campaign_leaders}
                        renderItem={renderLeaders}
                        keyExtractor={((item) => item.player_id.toString())}
                    />
                </View>
            </ScrollView>
            {!hide_back ?
            <View nativeID="result_actions" style={{ marginTop:5, padding:10 }}>
                <Button
                    title='Go Back'
                    title_color={Colors.brand.electric}
                    borderWidth={1}
                    padding={14}
                    borderColor={Colors.brand.electric}
                    onPress={() => onClose()}
                    borderRadius={22}
                />
            </View>
            :<></>}
        </View>
    )
}

export default CampaignResult