import React, { useEffect, useState } from "react"
import { View, FlatList, TouchableOpacity, Image, ActivityIndicator } from "react-native"
import { ManageCompetitionApi } from "../api"
import Colors from "../../constants/colors"
import { view_styles } from "../../constants/styles"
import { Button, Icons, Text } from "../../Components"
import type { CompetitionProps } from "../../types"

type AdminCompetitionListProps = {
    width:number,
    max_height?:number,
    onSelectCompetition:(competition:CompetitionProps) => void,
    onClose: () => void,
    onCreateNew: () => void
}

const AdminCompetitionList = ({ width, max_height, onClose, onCreateNew, onSelectCompetition }:AdminCompetitionListProps) => {
    const [ list_data, setData ] = useState<{
        loading: boolean,
        active_tab:'active'|'closed',
        offset:number,
        competitions:CompetitionProps[]
    }>({
        loading:false,
        offset:0,
        active_tab: 'active',
        competitions: []
    })
    const { offset, active_tab, loading, competitions } = list_data;

    useEffect(() => {
        ManageCompetitionApi.setEnvironment();
        getDataFromServer(0)
    },[active_tab])

    const getDataFromServer = async(offset:number) => {
        setData({ ...list_data, loading:true });
        const comps = await ManageCompetitionApi.getMyAdminCompetition(offset, active_tab);
        setData({ 
            ...list_data,  
            loading: false, 
            competitions: comps,
            offset
        })
    }

    const renderCompetitions = (data: { item:CompetitionProps, index:number }) => {
        return (
            <TouchableOpacity style={{ ...view_styles.body_row, padding:10, borderBottomWidth:1, borderColor:Colors.shades.shade600 }} onPress={() => onSelectCompetition(data.item)}>
                <Image
                    source={{ uri: data.item.image?.url }}
                    style={{ height:30, width:30, borderRadius:4 }}
                    resizeMode="cover"
                />
                <View style={{ flex:1, marginLeft:10 }}>
                    <Text theme="header_2">{data.item.competition_name}</Text>
                    <Text style={{ marginTop:3 }} theme="body">{data.item.competition_description}</Text>
                </View>
                <Icons.ChevronIcon direction='right' size={8} color={Colors.brand.midnight}/>
            </TouchableOpacity>
        )
    }

    return (
        <View style={{ maxWidth:width, minWidth:300, maxHeight:max_height ?? 700, backgroundColor:Colors.shades.white }}>
            <View style={{ ...view_styles.section_header }}>
                <View style={{ flex:1 }}>
                    <Text theme='header'>MY COMPETITIONS</Text>
                    <Text style={{ marginTop:3 }} theme="body">Below is a list of the competitions you have created</Text>
                </View>
                <Button
                    title="CREATE"
                    backgroundColor={Colors.utility.success}
                    title_color={Colors.shades.white}
                    onPress={() => onCreateNew()}
                />
            </View>
            <View style={{ flexDirection:'row' }}>
                <Button
                    title="ACTIVE"
                    title_color={active_tab == 'active' ? Colors.shades.white : Colors.brand.midnight}
                    backgroundColor={active_tab == 'active' ? Colors.brand.midnight: Colors.shades.white}
                    title_weight={active_tab == 'active' ? 'bold' : 'regular'}
                    borderRadius={0}
                    padding={15}
                    style={{ flex:1 }}
                    onPress={() => setData({ ...list_data, active_tab: 'active' })}
                />
                <Button
                    title="CLOSED"
                    title_color={active_tab == 'closed' ? Colors.shades.white : Colors.brand.midnight}
                    backgroundColor={active_tab == 'closed' ? Colors.brand.midnight: Colors.shades.white}
                    title_weight={active_tab == 'closed' ? 'bold' : 'regular'}
                    borderRadius={0}
                    padding={15}
                    style={{ flex:1 }}
                    onPress={() => setData({ ...list_data, active_tab: 'closed' })}
                />
            </View>
            <View style={{ ...view_styles.section_body }}>
                {loading ?
                <ActivityIndicator style={{ padding:20, alignSelf:'center' }} size='large' color={Colors.brand.midnight} />
                :<></>}
                <FlatList
                    data={competitions}
                    renderItem={renderCompetitions}
                    keyExtractor={(item) => item.competition_id.toString()}
                />
            </View>
            {active_tab == 'closed' ?
            <View style={{ ...view_styles.section_footer }}>
                {offset > 0 ?
                <Button
                    title='PREV'
                    title_color={Colors.brand.electric}
                    backgroundColor='transparent'
                    onPress={() =>  getDataFromServer(offset - 1)}
                />
                :<View/>}
                <View style={{ flex:1 }} />
                <Button
                    title='NEXT'
                    title_color={Colors.brand.electric}
                    backgroundColor='transparent'
                    onPress={() => getDataFromServer(offset + 1)}
                />
            </View>
            :<></>}
            <View style={{ ...view_styles.section_footer }}>
                <Button
                    title="CLOSE"
                    title_color={Colors.shades.white}
                    style={{ flex:1, marginRight:8 }}
                    padding={15}
                    backgroundColor={Colors.utility.error}
                    onPress={() => onClose()}
                />
            </View>
        </View>
    )
}


export default AdminCompetitionList