import React, { useEffect, useState } from 'react';
import { Image, View, TouchableOpacity, ActivityIndicator } from 'react-native';
import type { PollCampaignLeaderProps, PollProps, PollResponseProps, PublicPlayerProps } from '../../types';
import { PollCampaignApi, PollCampaignHelpers } from '../api';
import { Icons, Text } from '../../Components';
import Colors from '../../constants/colors';
import { view_styles } from '../../constants/styles';
type ResultCardProps = {
    poll_campaign_id:string,
    width:number,
    polls:PollProps[],
    poll_responses:PollResponseProps[],
    onClose:() => void
}

const ResultCard = ({ poll_campaign_id, polls, width, poll_responses, onClose }:ResultCardProps) => {
    const [ result_data, setData ] = useState<{
        loading:boolean,
        offset:number,
        players:PublicPlayerProps[]
        my_leader?:PollCampaignLeaderProps,
        selected_leader?:PollCampaignLeaderProps,
        poll_campaign_leaders:PollCampaignLeaderProps[]
    }>({
        loading:false,
        offset:0,
        players:[],
        poll_campaign_leaders:[]
    })

    const { loading, offset, poll_campaign_leaders, players, my_leader, selected_leader } = result_data;

    useEffect(() => {
        getResultsFromServer(0)
    },[poll_campaign_id])

    const getResultsFromServer = async(offset:number) => {
        setData({ ...result_data, loading: true })
        const resp = await PollCampaignApi.getLeadersByCampaignId(poll_campaign_id, offset)
        const player_ids = resp.poll_campaign_leaders.map(l => l.player_id);
        const ps = await PollCampaignApi.getPlayersByPlayerIds(player_ids);
        let new_my_leader:PollCampaignLeaderProps|undefined = my_leader;
        if(!new_my_leader){ new_my_leader = resp.my_leaderboard }
        setData({
            ...result_data,
            loading:false,
            poll_campaign_leaders: resp.poll_campaign_leaders,
            my_leader: new_my_leader,
            players:players.concat(ps),
            offset:offset
        })
    }

    /*
    const renderTrophy = (place:number) => {
        switch(place){
            case 1: return <Icons.TrophyIcon color={Colors.incentive.gold} size={16}/>
            case 2: return <Icons.TrophyIcon color={Colors.incentive.silver} size={16}/>
            case 3: return <Icons.TrophyIcon color={Colors.incentive.bronze} size={16}/>
            default: return <></>
        }
    }
    */

    const renderLeaders = (data: {item:PollCampaignLeaderProps, index:number}) => {
        const player = players.find(p => p.player_id == data.item.player_id);
        if(!player){ return <></> }
        return (
            <TouchableOpacity style={{ width:(width/5)-10, flexDirection:'row', alignItems:'center', margin:4, padding:10, borderRadius:22, backgroundColor:Colors.shades.white }}
                onPress={() => setData({ ...result_data, selected_leader: { ...data.item, place: parseInt(`${offset>0?offset:''}${(data.index+1)}`) } } )}>
                {width > 1000 ?
                <View nativeID="place" style={{ margin:5, height:20, width:20, justifyContent:'center', alignItems:'center', borderRadius:100, borderWidth:1, borderColor:Colors.brand.slate }}>
                    <Text size={10} color={Colors.brand.midnight} weight='bold' textAlign="center">{offset>0?offset:''}{(data.index+1)}</Text>
                </View>
                :<></>}
                <View nativeID="player" style={{ flex:1, flexDirection:width>1000?'row':'column', alignItems:'center' }}>
                    <View nativeID="profile_pic" style={{ marginRight:width>1000?10:0 }}>
                        <Image
                            source={{ uri: player.profile_pic && player.profile_pic != '' ? player.profile_pic : 'https://res.cloudinary.com/hoabts6mc/image/upload/v1689262384/default-avatar_bbkn2t.png' }}
                            style={{ height:40, width:40, borderRadius:100 }}
                            resizeMode='cover'
                        />
                    </View>
                    <View style={{ flex:1 }} nativeID="name">
                        {width > 600 ?
                        <Text size={14} color={Colors.brand.midnight} weight='bold'>{player.username}</Text>
                        :<></>}
                        <Text style={{ marginTop:3 }} size={12} color={Colors.brand.midnight} weight='regular' textAlign={width>1000?'left':'center'}>{data.item.winnings.toFixed(2)} Points</Text>
                    </View>
                   
                </View>
            </TouchableOpacity>
        )
    }

    const me = players.find(p => p.player_id == my_leader?.player_id);
    //const questions_answered = poll_responses.length
    let correct_answers = poll_responses.filter(pr => pr.result_ind == 'win').length
    //let incorrect_answers = poll_responses.filter(pr => pr.result_ind == 'lose').length
    let ungraded_answers = poll_responses.filter(pr => !pr.result_ind).length
    const selected_player = players.find(p => p.player_id == selected_leader?.player_id);
    return (
        <View>
            <View nativeID="my_results" style={{ padding:10, backgroundColor:Colors.incentive.gold_faded }}>
                {me && my_leader ?
                <View style={{ flexDirection:'row', alignItems:'center' }}>
                    <View style={{ padding:10, paddingRight:20, paddingLeft:20, flexDirection:width>600 ?'row': 'column', alignItems:'center', justifyContent:'center' }}>
                        <Image
                            source={{ uri: me.profile_pic && me.profile_pic != '' ? me.profile_pic : 'https://res.cloudinary.com/hoabts6mc/image/upload/v1689262384/default-avatar_bbkn2t.png' }}
                            style={{ height:40, width:40, borderRadius:100 }}
                            resizeMode='cover'
                        />
                        <View style={{ marginLeft:width > 600 ? 10: 0 }}>
                            <Text size={14} color={Colors.brand.midnight} weight='bold' textAlign={width > 600 ? 'left':'center'}>{me.username}</Text>
                            <Text style={{ marginTop:3 }} size={12} color={Colors.brand.midnight} weight='regular' textAlign={width>600?'left':'center'}>{my_leader.winnings.toFixed(2)} Points</Text>
                        </View>
                    </View>
                    <View style={{ flex:1, flexDirection:'row', alignItems:'center' }}>
                        <View nativeID="total_questions" style={{ flex:1, justifyContent:'center', alignItems:'center' }}>
                            <Text size={12} color={Colors.brand.slate} weight='semibold'>TOTAL QUESTIONS</Text>
                            <Text style={{ marginTop:5 }} size={14} color={Colors.brand.midnight} weight='bold'>{polls.length} Questions</Text>
                        </View>
                        <View nativeID="completion_pct" style={{ flex:1, justifyContent:'center', alignItems:'center' }}>
                            <Text size={12} color={Colors.brand.slate} weight='semibold'>COMPLETION</Text>
                            <Text style={{ marginTop:5 }} size={14} color={Colors.brand.midnight} weight='bold'>{(((polls.length - ungraded_answers) / polls.length) * 100).toFixed()}%</Text>
                        </View>
                        <View nativeID="total_questions" style={{ flex:1, justifyContent:'center', alignItems:'center' }}>
                            <Text size={12} color={Colors.brand.slate} weight='semibold'>SUCCESS RATE</Text>
                            <Text style={{ marginTop:5 }} size={14} color={Colors.brand.midnight} weight='bold'>{((correct_answers / polls.length)*100).toFixed()}%</Text>
                        </View>
                    </View> 
                </View>
                :<></>}
            </View>
            <View>
                <View style={{ flexDirection:'row', alignItems:'center', flexWrap:'wrap' }}>
                    {poll_campaign_leaders.map((l, i) => {
                        return (
                            renderLeaders({ item: l, index:i })
                        )
                    })}
                    {loading ?
                    <View style={{ position:'absolute', top:0, left:0, right:0, bottom:0, justifyContent:'center', alignItems:'center' }}>
                        <ActivityIndicator size='large' color={Colors.brand.midnight} />
                    </View>
                    :<></>}
                </View>
                {offset > 0 ?
                    <TouchableOpacity style={{ position:'absolute', left:10, top:-15, borderRadius:100, height:30, width:30, justifyContent:'center', alignItems:'center', backgroundColor:Colors.shades.white, ...view_styles.float }}
                    onPress={async() => {
                        getResultsFromServer(offset - 1)
                    }}>
                        <Icons.ChevronIcon direction='left' color={Colors.brand.midnight} size={12} />
                    </TouchableOpacity>
                    :<></>}
                    <TouchableOpacity style={{ position:'absolute', right:10, top:-15, borderRadius:100, height:30, width:30, justifyContent:'center', alignItems:'center', backgroundColor:Colors.shades.white, ...view_styles.float }}
                    onPress={async() => {
                        getResultsFromServer(offset + 1)
                    }}>
                        <Icons.ChevronIcon direction='right' color={Colors.brand.midnight} size={12} />
                    </TouchableOpacity>
                    {selected_leader && selected_player ?
                    <TouchableOpacity style={{ position:'absolute', top:0, left:0, right:0, bottom:0, backgroundColor:Colors.shades.black_faded_heavy, justifyContent:'center', alignItems:'center' }} onPress={() => setData({ ...result_data, selected_leader: undefined })}>
                        <View style={{ flexDirection:'row', backgroundColor:Colors.shades.white, borderRadius:8, ...view_styles.float }}>
                            <View>
                                <Image
                                    source={{ uri: selected_player.profile_pic }}
                                    style={{ height:70, width:70, borderTopLeftRadius:8, borderBottomLeftRadius:8 }}
                                    resizeMode='cover'
                                />
                            </View>
                            <View style={{ flex:1, padding:10 }}>
                                <Text size={16} color={Colors.brand.midnight} weight='bold'>{selected_player.username}</Text>
                                <Text style={{ marginTop:5 }} size={14} color={Colors.brand.midnight} weight='regular'>{selected_leader.winnings.toFixed(2)} Points</Text>
                            </View>
                            <View style={{ paddingRight:15, paddingLeft:15, borderTopRightRadius:8, borderBottomRightRadius:8, backgroundColor:Colors.incentive.gold_faded, justifyContent:'center', alignItems:'center' }}>
                                <Text size={18} color={Colors.brand.midnight} weight='bold' textAlign='center'>{PollCampaignHelpers.formatPlace(selected_leader.place)}</Text>
                            </View>
                        </View>
                    </TouchableOpacity>
                    :<></>}
            </View>
            <View style={{ flexDirection:'row', padding:10 }}>
                <TouchableOpacity style={{ flexDirection:'row', alignItems:'center' }} onPress={() => onClose()}>
                    <Icons.ChevronIcon size={14} direction='left' color={Colors.brand.midnight} />
                    <Text style={{ marginLeft:15 }} size={14} color={Colors.brand.midnight} weight='bold'>Review Questions</Text>
                </TouchableOpacity>
            </View>
        </View>
    )

}

export default ResultCard