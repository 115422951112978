import React, { createContext, useContext, useEffect, useState } from 'react';
import { View, Linking } from "react-native"
import { AdServerApi, AdServerHelpers } from './api';
import type { AdPlacementProps, AdTypeProps } from '../types';
import CompanyAdCard from './components/CompanyAdCard';
import type { BEEventProps } from '../Observer/api/types';


type AdServerProps = {
    placement:string,
    ad_type:string,
    role?:string,
    onViewAd: (be_event:BEEventProps) => void,
    onClickAd: (be_event:BEEventProps) => void,
}

const AdContext = createContext({
    loading:false,
    role: undefined as string | undefined,
    ad_types:[] as AdTypeProps[],
    ad_placements: [] as AdPlacementProps[],
    distinct_id: undefined as string | undefined,
    session_id: undefined as string | undefined
})

type AdProviderProps = {
    children:any,
    role?:string,
    distinct_id?:string,
    session_id?:string
}

export const AdProvider = ({ children, role, distinct_id, session_id }:AdProviderProps) => {
    const [ loaded, setLoaded ] = useState(false);
    const [ ads_data, setData ] = useState<{
        loading:boolean,
        role:string | undefined,
        ad_types:AdTypeProps[],
        ad_placements:AdPlacementProps[],
    }>({
        loading:false,
        ad_types: [],
        role:undefined,
        ad_placements:[],
    })

    useEffect(() => {
        if(!loaded){ return }
        setData({ ...ads_data, role })
    },[role])

    useEffect(() => {
        AdServerApi.setEnvironment();
        getAds()
    },[])

    const getAds = async() => {
        const ad_plcm = await AdServerApi.findAds();
        const typs = await AdServerApi.getAdTypes();

        setData({
            ...ads_data,
            ad_types:typs,
            role,
            ad_placements:ad_plcm
        })
        setLoaded(true)
    }


    return (
        <AdContext.Provider value={{ ...ads_data, distinct_id, session_id }}>
            {children}
        </AdContext.Provider>
    )
}

const AdServer = ({ placement, ad_type, role, onViewAd, onClickAd }:AdServerProps) => {
    const [ size, setSize ] = useState({ width:0, height:0 });

    const data = useContext(AdContext);
    let my_role = role;
    if(!my_role){ role = data.role }
    const active_type = data.ad_types.find(t => t.ad_type == ad_type)
    const active_placement = data.ad_placements.find(p => p.placement == placement)
    if(!active_placement?.ad_buys){ return <></> }
    const active_ad_buy = active_placement.ad_buys[Math.floor(Math.random()*active_placement.ad_buys.length)]
    if(!active_ad_buy){ return <></> }
    let selectedable_ads = active_ad_buy.company_ads
    if(!selectedable_ads){ return <></> }
    selectedable_ads = selectedable_ads.filter(ad => ad.ad_type_id == active_type?.ad_type_id);
    const active_ad = selectedable_ads[Math.floor(Math.random()*selectedable_ads.length)]
    if(!active_ad || !active_type || my_role == 'premium'){ return <></> }
        return (
        <View style={{ flex:1, justifyContent:'center', alignItems:'center' }} onLayout={(ev) => {
            const { width, height } = ev.nativeEvent.layout;
            setSize({ width, height })
        }}>
            <CompanyAdCard
                company_ad={active_ad}
                ad_type={active_type}
                size={size}
                onViewAd={(ad) => {
                    onViewAd({
                        event_name:'ad_view',
                        level:2,
                        event_data: {
                            ad_placement_id:active_placement.ad_placement_id,
                            company_ad_buy_id: ad.company_ad_buy_id,
                            company_ad_id: ad.company_ad_id,
                            company_id: ad.company_id,
                            ad_campaigns: ad.ad_campaigns
                            
                        }
                    })
                }}
                onClickAd={(ad) => {
                    onClickAd({
                        event_name:'ad_click',
                        level:1,
                        event_data: {
                            ad_placement_id:active_placement.ad_placement_id,
                            company_ad_buy_id: ad.company_ad_buy_id,
                            company_ad_id: ad.company_ad_id,
                            company_id: ad.company_id,
                            ad_campaigns: ad.ad_campaigns
                            
                        }
                    })
                    const new_url = AdServerHelpers.generateUrl(ad.landing_url, data.distinct_id)
                    Linking.openURL(new_url)
                }}
            />
        </View>
    )
}

export default AdServer