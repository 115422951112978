import React from 'react';
import { View, Image, TouchableOpacity } from 'react-native';
import type { CompanyProps, CompetitionProps, CompetitionResultTypeProps, CompetitionTypeProps, PublicPlayerProps } from '../../types';
import { view_styles } from '../../constants/styles';
import Colors from '../../constants/colors';
import { Icons, Text } from '../../Components';
import moment from 'moment-mini';

type CompetitionCardProps = {
    competition:CompetitionProps,
    competition_type:CompetitionTypeProps,
    competition_result_type:CompetitionResultTypeProps,
    company?:CompanyProps,
    admin?:PublicPlayerProps,
    pacer?:PublicPlayerProps,
    onCompetitionSelect:(c:CompetitionProps) => void
}
const CompetitionCard = ({ competition, competition_type, company, admin, onCompetitionSelect }:CompetitionCardProps) => {
    const cl = competition.market_type == 'FOR_MONEY' ? '$' : 'E'
    let current_payout = competition.ticket_revenue
    if(competition.promo_payout){ current_payout += competition.promo_payout }
    if(competition.guaranteed_payout && competition.guaranteed_payout > current_payout){ current_payout = competition.guaranteed_payout }

    const tickets_available = parseFloat(competition.available_tickets as string) - competition.tickets_sold
    const is_live = moment().isAfter(moment(competition.scheduled_datetime)) ? true : false
    const getTypeIcon = () => {
        switch(competition_type.type){
            case 'pick':
                return <Icons.SelectorIcon size={20} color={Colors.brand.midnight} />
            default: return <></>
        }
    }
    
    return (
        <TouchableOpacity style={{ ...view_styles.section, padding:0, borderWidth:1, borderColor:Colors.shades.shade600 }} onPress={() => onCompetitionSelect(competition)}>
            <View style={{ ...view_styles.section_header, backgroundColor:Colors.shades.shade100 }}>
                <Image
                    source={{ uri: competition.image?.url ?? 'https://res.cloudinary.com/hoabts6mc/image/upload/v1722453927/default_man_n96ofq.webp' }}
                    style={{ height:45, width:45, borderRadius:4}}
                    resizeMode='cover'
                />
                <View style={{ flex:1, marginLeft:10, marginRight:10, borderRightWidth:1, borderRightColor:Colors.shades.shade600 }}>
                    <Text theme='header_2'>{competition.competition_name}</Text>
                    <Text style={{ marginTop:3 }} theme='body'>{competition.competition_description}</Text>
                </View>
                <Text size={20} color={Colors.brand.electric} weight='bold'>{cl}{competition.buy_in}</Text>
            </View>
            <View style={{ ...view_styles.section_body }}>
                <View style={{ ...view_styles.body_row }}>
                    
                    {tickets_available > 0 ? 
                    <View style={{ flex:1, justifyContent:'center', alignItems:'center' }}>
                        <Text size={18} color={Colors.brand.midnight} weight='bold'>{tickets_available}</Text>
                        <Text style={{ marginTop:3 }} theme='body'>TICKETS</Text>
                    </View>
                    :
                    <View style={{ flex:1, justifyContent:'center', alignItems:'center' }}>
                        <Text size={18} color={Colors.utility.error} weight='bold'>FULL</Text>
                    </View>
                    }
                    {competition_type.type == 'pick' ?
                    <View style={{ flex:1, justifyContent:'center', alignItems:'center' }}>
                        <Text size={18} color={Colors.brand.midnight} weight='bold'>{competition.max_pick_count}</Text>
                        <Text style={{ marginTop:3 }} theme='body'>PICKS</Text>
                    </View>
                    :
                    <View style={{ flex:1, justifyContent:'center', alignItems:'center' }}>
                        <Text size={18} color={Colors.brand.midnight} weight='bold' textAlign='center'>E{competition.initial_balance}</Text>
                        <Text style={{ marginTop:3 }} size={14} color={Colors.brand.midnight} textAlign='center'>STARTING BALANCE</Text>
                    </View>
                    }
                    <View style={{ flex:1, justifyContent:'center', alignItems:'center' }}>
                        {getTypeIcon()}
                        <Text style={{ marginTop:3 }} theme='body'>{competition_type.type_label.toUpperCase()}</Text>
                    </View>
                </View>
            </View>
            <View style={{ ...view_styles.section_footer, backgroundColor:company?Colors.incentive.gold_faded:Colors.shades.shade100 }}>
                {company ?
                <Image
                    source={{ uri: company.company_image?.url ?? 'https://res.cloudinary.com/hoabts6mc/image/upload/v1722453927/default_man_n96ofq.webp' }}
                    style={{ height:30, width:30, borderRadius:4}}
                    resizeMode='cover'
                />
                :admin ?
                <Image
                    source={{ uri: admin.profile_pic && admin.profile_pic != '' ? admin.profile_pic : 'https://res.cloudinary.com/hoabts6mc/image/upload/v1722453927/default_man_n96ofq.webp' }}
                    style={{ height:30, width:30, borderRadius:4}}
                    resizeMode='cover'
                />
                :<></>}
                {competition.prize_override ?
                <View style={{ flex:1, flexDirection:'row', alignItems:'center', marginLeft:10 }}>
                    <View style={{ flex:1, marginRight:10}}>
                        <Text size={12} weight='bold' color={Colors.brand.midnight}>ADDITIONAL PRIZE</Text>
                        <Text style={{ marginTop:3 }} size={10} color={Colors.incentive.gold} weight='bold'>{competition.prize_override}</Text>
                    </View>
                    {competition.prize_image?.url ?
                    <Image
                        source={{ uri: competition.prize_image.url }}
                        style={{ height:30, width:30, borderRadius:4}}
                        resizeMode='cover'
                    />
                    :
                    <Icons.AwardRibbonIcon size={24} color={Colors.incentive.gold} />
                    }
                </View>
                :
                <View style={{ flex:1, flexDirection:'row', alignItems:'center' }}>
                    <View style={{ flex:1, marginLeft:20, marginRight:20 }}>
                        <Text theme='header_2'>CURRENT PAYOUT</Text>
                        {is_live ? 
                        <Text style={{ marginTop:3 }} color={Colors.utility.error} weight='bold' size={12}>IN PROGRESS</Text>
                        :
                        <Text style={{ marginTop:3 }} theme='body_2'>Join Until {moment(competition.scheduled_datetime).format('ddd hh:mm a')}</Text>
                        }
                    </View>
                    <Text color={Colors.utility.success} size={20} weight='bold'>{cl}{current_payout.toFixed(2)}</Text>
                </View>
                }
            </View>
        </TouchableOpacity>
    )
}

export default CompetitionCard