import React from 'react';
import { TouchableOpacity, View } from "react-native"
import type { PollCampaignProps, PollOptionProps, PollProps, PollResponseProps } from "../../types"
import Colors from "../../constants/colors"
import { Icons, Text } from "../../Components"

type PollSelectCardProps = {
    selected?:boolean,
    poll_campaign:PollCampaignProps,
    poll:PollProps,
    disabled?:boolean,
    index:number,
    show_response?:boolean,
    response_option?:PollOptionProps,
    poll_response?:PollResponseProps,
    onSelect:(poll:PollProps) => void
}

const PollSelectCard = ({ index, poll, disabled, selected, poll_campaign, poll_response, show_response, response_option, onSelect }:PollSelectCardProps) => {

    const getResultColor = () => {
        switch(poll_response?.result_ind){
            case 'win': return Colors.utility.success
            case 'lose': return Colors.utility.error
            case 'draw': return Colors.utility.warning
            default: return Colors.brand.slate
        }
    }

    const getStatusColor = () => {
        switch(poll.status) {
            case 'closed': return Colors.highlights.highlight300Faded
            case 'active': return Colors.highlights.highlight400Faded
            default: return Colors.highlights.highlight500Faded
        }
    }


    const getResultIcon = () => {
        let color = getResultColor()
        switch(poll_response?.result_ind){
            case 'win':
                return <Icons.CheckIcon color={color} size={16} />
            case 'lose':
                return <Icons.CloseIcon color={color} size={12} />
            default: 
                if(poll.status == 'closed'){
                    return <Icons.CloseIcon color={color} size={10} />
                }
                return <></>
        }
    }

    const getStatusIcon = () => {
        //let color = getResultColor()
        switch(poll.status){
            case 'closed':
                return <Icons.LockClosedIcon color={Colors.brand.midnight} size={14} />
            case 'active':
                return <Icons.InProgressIcon color={Colors.brand.midnight} size={14} />
            default:
                return <Icons.PausedIcon color={Colors.brand.midnight} size={14} />
        }
    }

    return (
        <TouchableOpacity disabled={disabled} style={{ padding:10, borderRadius:22, backgroundColor:selected?Colors.highlights.highlight500Faded:undefined, flexDirection:'row' }} onPress={() => onSelect(poll)}>
        <View style={{ height:20, width:20, borderRadius:100, backgroundColor:Colors.shades.white, marginRight:10, justifyContent:'center' }}>
            <Text size={12} color={Colors.brand.midnight} weight='bold' textAlign='center'>{(index + 1).toString()}</Text>
        </View>
        <View style={{ flex:1 }}>
            {poll_campaign.campaign_type == 'trivia' && !poll_response && poll.status != 'closed' ? 
            <Text size={14} color={Colors.brand.midnight} weight='bold'>{poll.hidden_clue?`Hint: ${poll.hidden_clue}`:`Question Hidden`}</Text>
            :
            <Text size={14} color={Colors.brand.midnight} weight='bold'>{poll.poll_question}</Text>
            }
            {poll.status == 'closed' || show_response ?
            <View style={{ marginTop:10, flexDirection:'row' }}>
                {response_option && poll.poll_type == 'select' ?
                <Text style={{ flex:1 }} size={12} color={Colors.brand.slate}>My Answer: <Text size={12} color={Colors.brand.midnight} weight='semibold'>{response_option.option_name}</Text></Text>
                : response_option && poll.poll_type == 'input' ?
                <Text style={{ flex:1 }} size={12} color={Colors.brand.slate}>My Answer: <Text size={12} color={Colors.brand.midnight} weight='semibold'>{poll_response?.input_value}</Text></Text>
                :poll.status == 'closed' || poll_response?.timed_out ?
                <Text style={{ flex:1 }} size={12} color={Colors.brand.midnight} weight='light'>TIMED OUT</Text>
                :<></>}
                <View style={{ marginLeft:5 }}>
                    {getResultIcon()}
                </View>
            </View>
            :
            <View style={{flexDirection:'row', alignItems:'center'}}>
                <Text style={{ flex:1 }} size={12} color={Colors.brand.midnight} weight='light'>{poll.poll_type == 'select' ? 'Multiple Choice': 'Type Answer'}</Text>
                <View style={{ flexDirection:'row', alignItems:'center', padding:10, backgroundColor:getStatusColor(), borderRadius:22 }}>
                    {getStatusIcon()}
                    {poll.seconds_allowed && poll.status != 'closed' ? 
                    <Text style={{ marginLeft:5 }} size={12} color={Colors.brand.midnight} weight='bold'>{poll.seconds_allowed} Sec</Text>
                    :<></>}
                </View>
            </View>
            }
        </View>
    </TouchableOpacity>
    )
}

export default PollSelectCard