import React, { useState } from 'react';
import { TouchableOpacity, View, Image, FlatList } from "react-native"
import type { CompetitionPayoutTypeProps, CompetitionProps, CompetitionResultTypeProps, CompetitionTypeProps, MyPlayerProps } from "../../types"
import { Icons, Text } from '../../Components';
import Colors from '../../constants/colors';
import { view_styles } from '../../constants/styles';
import { CompetitionHelpers } from '../api';

type CompetitionInfoCardProps = {
    competition:CompetitionProps,
    competition_type:CompetitionTypeProps,
    competition_result_type:CompetitionResultTypeProps,
    competition_payouts:CompetitionPayoutTypeProps[],
    player?:MyPlayerProps,
}
const CompetitionInfoCard = ({ competition, competition_type, competition_result_type, competition_payouts }:CompetitionInfoCardProps) => {
    const [ expanded, setExpanded ] = useState(false);
    
    const cl = competition.market_type == 'FOR_MONEY' ? '$' : 'E'

    let potential_winnings = competition.ticket_revenue
    if(competition.promo_payout){
        potential_winnings += competition.promo_payout
    }
    if(competition.guaranteed_payout){
        if(potential_winnings < competition.guaranteed_payout){ potential_winnings = competition.guaranteed_payout }
    }

    const renderPayouts = (data: { item:CompetitionPayoutTypeProps, index:number }) => {
        return (
            <View style={{ ...view_styles.body_row, padding:8, borderBottomWidth:1, borderColor:Colors.shades.shade600 }}>
                <Text style={{ flex:1 }} theme='header_2'>{CompetitionHelpers.formatPlace(data.item.tier)}{data.item.tier!= 1 ? ' Or Better': ''}</Text>
                <Text theme='header_2'>Split {data.item.payout}%</Text>
            </View>
        )
    }

    return (
        <View nativeID="competition_details" style={{ ...view_styles.section, minWidth:350 }}>
            <TouchableOpacity style={view_styles.section_header} onPress={() => setExpanded(!expanded)}>
                <Icons.SettingsIcon size={18} color={Colors.brand.midnight} />
                <View style={{ flex:1, marginLeft:10 }}>
                    <Text theme="header">COMPETITION DETAILS</Text>
                    <Text style={{ marginTop: 4 }} theme="body_2">Detailed Information on this competition</Text>
                </View>
                <View style={{ margin:10 }}>
                    <Text color={Colors.utility.success} weight='bold'>{cl}{potential_winnings.toFixed(2)}</Text>
                </View>
                <Icons.ChevronIcon direction={expanded?'up':'down'} color={Colors.brand.midnight} size={8} />
            </TouchableOpacity>
            {expanded ?
            <View style={{ ...view_styles.section_body }}>
                <View style={view_styles.body_row}>
                    <Text style={{flex:1}} theme="body">Competition Type</Text>
                    <Text theme="header_2">{competition_type.type.toUpperCase()}</Text>
                </View>
                {competition_type.type == 'pick' ?
                <View style={view_styles.body_row}>
                    <Text style={{flex:1}} theme="body">Max Pick Count</Text>
                    <Text theme="header_2">{competition.max_pick_count} Picks</Text>
                </View>
                :
                <View style={view_styles.body_row}>
                    <Text style={{flex:1}} theme="body">Initial Balance</Text>
                    <Text theme="header_2">E{competition.initial_balance}</Text>
                </View>
                }
                <View style={view_styles.body_row}>
                    <Text style={{flex:1}} theme="body">Current Payout</Text>
                    <Text theme="header_2">{cl}{potential_winnings.toFixed(2)}</Text>
                </View>
                {competition.prize_override ?
                <View style={{ padding:10, borderRadius:8, backgroundColor:Colors.incentive.gold_faded }}>
                    <Text size={14} color={Colors.incentive.gold} weight='bold'>Additional Prize</Text>
                    <View style={view_styles.body_row}>
                        <Image
                            source={{ uri: competition.prize_image?.url ?? 'https://res.cloudinary.com/hoabts6mc/image/upload/v1649737862/be_logo_jte2ux.webp' }}
                            style={{ height:50, width:50, borderRadius:4 }}
                            resizeMode='cover'
                        />
                        <Text style={{ marginLeft:10 }} size={14} weight='bold' color={Colors.brand.midnight}>{competition.prize_override}</Text>
                    </View>
                </View>
                :<></>}
                <View style={view_styles.body_row}>
                    <Text style={{flex:1}} theme="body">Entries</Text>
                    <Text theme="header_2">{competition.tickets_sold} / {competition.available_tickets}</Text>
                </View>
                <View style={view_styles.body_row}>
                    <Text style={{flex:1}} theme="body">Payout Type</Text>
                    <Text theme="header_2">{competition_result_type.label.toUpperCase()}</Text>
                </View>
                <View style={{ padding:10, borderRadius:8, backgroundColor:Colors.shades.shade100 }}>
                    <Text style={{ padding:5 }} theme="header_2">{competition_result_type.description}</Text>
                    <View style={{ padding:10 }}>
                        <FlatList
                            data={competition_payouts.sort((a,b) => b.tier - a.tier)}
                            renderItem={renderPayouts}
                            keyExtractor={(item) => item.competition_payout_type_id.toString()}
                        />
                    </View>
                </View>
            </View>
            :<></>}
        </View>
    )

    /*
    return (
        <View>
            <View nativeID="competition_info">
                <View style={{ padding:10 }}>
                    <Text size={16} color={Colors.shades.white} weight='bold' textAlign="center">{competition.competition_name}</Text>
                    {competition_type ?
                    <View style={{ flexDirection:'row', justifyContent:'center', padding:10}}>
                        
                        <View style={{ padding:10, marginRight:4, justifyContent:'center', backgroundColor: CompetitionHelpers.getCompTypeColor(competition_type), borderRadius:4 }}>
                            <Text size={12} color={Colors.shades.white} weight='semibold'>{competition_type.type_label}</Text>
                        </View>
                        <View style={{ flexDirection:'row', alignItems:'center', padding:10, marginLeft:4, backgroundColor: Colors.shades.white, borderRadius:4 }}>
                            <Text style={{ marginRight:5 }} size={12} color={Colors.brand.midnight} weight='semibold'>{competition.invite_only?'Private':'Public'}</Text>
                            {competition.invite_only ?
                            <Icons.EyeOffIcon color={Colors.brand.midnight} size={14}/>
                            :
                            <Icons.EyeOnIcon color={Colors.brand.midnight} size={14}/>
                            }
                        </View>
                        <TouchableOpacity 
                            style={{ flexDirection:'row', alignItems:'center', padding:10, marginLeft:8, backgroundColor: Colors.incentive.gold, borderRadius:4 }}
                            onPress={() => onViewRules ? onViewRules(): console.log('')}>
                            {competition.prize_image ? 
                            <Image
                                source={{ uri: competition.prize_image.url }}
                                style={{ height:20, width:20, marginRight:10 }}
                                resizeMode="center"
                            />
                            :<></>}
                            <Text size={12} color={Colors.shades.white} weight='semibold'>VIEW RULES</Text>
                        </TouchableOpacity>
                    </View>
                    :<></>}
                    <View style={{ flexDirection:'row', padding:10, justifyContent:'center' }}>
                        <View style={{ padding:10, borderRightWidth:1, borderColor:Colors.shades.shade600 }}>
                            <Text color={Colors.shades.white} size={16} weight='bold' textAlign="center">{cl}{potential_winnings.toFixed(2)}</Text>
                            <Text style={{ marginTop:3 }} color={Colors.shades.white} size={12} textAlign="center">POT</Text>
                        </View>
                        <View style={{ padding:10, borderRightWidth:1, borderColor:Colors.shades.shade600 }}>
                            <Text color={Colors.shades.white} size={16} weight='bold' textAlign="center">{competition.tickets_sold} / {competition.available_tickets}</Text>
                            <Text style={{ marginTop:3 }} color={Colors.shades.white} size={12} textAlign="center">ENTRIES</Text>
                        </View>
                        {competition_type?.type == 'pick' ?
                        <View style={{ padding:10 }}>
                            <Text color={Colors.shades.white} size={16} weight='bold' textAlign="center">{competition.max_pick_count}</Text>
                            <Text style={{ marginTop:3 }} color={Colors.shades.white} size={12} textAlign="center">PICKS</Text>
                        </View>
                        :competition_type?.type == 'wager' ?
                        <View style={{ padding:10 }}>
                            <Text color={Colors.shades.white} size={16} weight='bold' textAlign="center">{competition.initial_balance}</Text>
                            <Text style={{ marginTop:3 }} color={Colors.shades.white} size={12} textAlign="center">INIT. BALANCE</Text>
                        </View>
                    :<></>}
                    </View>
                </View>
            </View>
            <View nativeID="competition_actions" style={{ flexDirection:'row', padding:10 }}>
                <TouchableOpacity 
                    style={{ padding:10, borderRadius:22, borderWidth:1, borderColor:Colors.shades.white, marginRight:10, justifyContent:'center', alignItems:'center' }}
                    onPress={() => handleShare()}>
                    <Icons.ShareIcon size={14} color={Colors.shades.white}/>
                </TouchableOpacity>
                <Button
                    title={competition.entered?can_leave?'Leave':'My Picks':'Preview Events'}
                    padding={10}
                    style={{ flex:1, marginRight:3 }}
                    title_color={can_leave?Colors.utility.error:Colors.shades.white}
                    onPress={() => {
                        if(can_leave && onLeave){ return onLeave() }
                        return onViewEvents()
                    }}
                    borderRadius={22}
                    borderWidth={1}
                    backgroundColor="transparent"
                    borderColor={can_leave?Colors.utility.error:Colors.shades.white}
                />
                <Button
                    title={competition.entered || !can_enter ? 'In Progress' : 'Enter Competition'}
                    padding={10}
                    disabled={competition.entered || !can_enter}
                    style={{ flex:1, marginLeft:3 }}
                    title_color={Colors.shades.white}
                    onPress={() => {
                        if(competition.entered){ return }
                        return onEnter(competition.competition_id)
                    }}
                    borderRadius={22}
                    backgroundColor={ competition.entered ? Colors.accents.accent100 : Colors.utility.success}
                    borderWidth={1}
                />
            </View>
        </View>
    )
        */
}

export default CompetitionInfoCard