import { FlatList, TouchableOpacity, View } from "react-native"
import React, { useState } from "react"
import type { PollCampaignProps, PollProps, PollResponseProps } from '../../types';
import Colors from '../../constants/colors';
import { Icons, Text } from '../../Components';

type CampaignProgressBarProps = {
    poll_campaign:PollCampaignProps,
    polls:PollProps[],
    poll_responses:PollResponseProps[],
    active_poll:string,
    onPollSelect:(poll:PollProps) => void
}

const CampaignProgressBar = ({ poll_responses, active_poll, polls, onPollSelect}:CampaignProgressBarProps) => {
    const [ progress_width, setProgressWidth ] = useState(0)
    const getBarColor = (pr:PollResponseProps) => {
        switch(pr.result_ind){
            case 'win': return Colors.utility.success
            case 'lose': return Colors.utility.error
            default: return Colors.utility.warning
        }
    }
    
    const renderProgress = (data: {item: PollProps, index:number }) => {
        let width = progress_width / polls.length
        let color = Colors.shades.white
        let response = poll_responses.find(pr => pr.poll_id == data.item.poll_id)
        if(response){ color = getBarColor(response) }
        console.log('rendering!!!')
        const active = active_poll == data.item.poll_id ? true : false
        return (
            <TouchableOpacity
                onPress={() => onPollSelect(data.item)} 
                style={{ margin:2, borderWidth:active?2:0, borderColor:Colors.brand.midnight, borderRadius:4, height:20, width: width - 4, backgroundColor:color }} 
            />
        )
    }

    const questions_answered = poll_responses.length
    const questions_unanswered = polls.length - questions_answered

    return (
        <View style={{ flexDirection:'row', alignItems:'center' }}>
            <View nativeID="questions_answered" style={{ flexDirection:'row', alignItems:'center', margin:5, borderRadius:4, backgroundColor:Colors.highlights.highlight400, padding:5 }}>
                <Icons.UserIcon color={Colors.shades.white} size={12} />
                <Text style={{ marginLeft:5 }} size={12} color={Colors.shades.white} weight='semibold'>{questions_answered}</Text>
            </View>
            <View nativeID="progress_bar" style={{ flex:1 }} onLayout={(ev) => {
                const { width } = ev.nativeEvent.layout;
                setProgressWidth(width)
            }}>
                <FlatList
                    data={polls}
                    horizontal
                    showsHorizontalScrollIndicator={false}
                    renderItem={renderProgress}
                />
            </View>
            <View nativeID="questions_answered" style={{ flexDirection:'row', alignItems:'center', margin:5, borderRadius:4, backgroundColor:Colors.utility.warning, padding:5 }}>
                <Icons.ListIcon color={Colors.shades.white} size={12} />
                <Text style={{ marginLeft:5 }} size={12} color={Colors.shades.white} weight='semibold'>{questions_unanswered}</Text>
            </View>
        </View>
    )
}

export default CampaignProgressBar