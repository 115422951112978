import { useEffect, useState } from 'react';
import { LocationApi, LocationHelpers } from './api';
import type { LocationProps, PlayerAddressProps } from '../types';

type UsePlayerLocationProps = {
    player_id?:string,
    distinct_id?:string
}

export const usePlayerLocation = ({ player_id, distinct_id }:UsePlayerLocationProps) => {
    const [ location, setLocation ] = useState<{
        loading: boolean,
        error?:string,
        coordinates:any,
        player_id?:string,
        address_results:any[],
        player_address?:PlayerAddressProps,
        region?:string,
        location?:LocationProps
    }>({
        loading:false,
        coordinates: {},
        address_results:[]
    })
    const [ permission, setPermission ] = useState<{
        checked:boolean,
        permission?: 'granted'|'denied'|'prompt'
    }>({
        checked:false
    })
       
    useEffect(() => {
        LocationApi.setEnvironment();
    },[])

    const checkPermissions = async(options?: { auto_on_granted?:boolean }) => {
        try {
           const permission = await LocationApi.getPermission();
           setPermission({
            checked: true,
            permission: permission
           })
           if(permission == 'granted' && options?.auto_on_granted){
            getLocation()
           }
        } catch (e) {
            console.log('error') 
            console.log(e)  
        }
       
    }

    const getLocation = async() => {
        if(location.loading){ return } //No mashy mashy
        setLocation({ ...location, loading: true })
        try {

            let cached_loc = await LocationApi.getCachedLocation();
            if(cached_loc){
                setLocation(cached_loc)
                setPermission({ checked: true, permission: 'granted' });
            } else {
                const coordinates = await LocationApi.getPosition();
                setPermission({
                    checked:true,
                    permission: 'granted'
                })
                const locations = await LocationApi.getLocations();

                //OK! First we are going to try and compare to the players last location
                //If the user is within a mile, then we will use their last one for this
                const { last_location, distance } = await LocationApi.compareLastLocation({ anonymous_id:distinct_id, player_id: player_id ?? '0', coordinates:coordinates, player_location_id: '', create_datetime: '' })
                if(last_location && last_location.player_address && distance < 5){
                    const gen_addy = LocationHelpers.getAddressFromLastLocation(last_location, locations);
                    if(gen_addy){
                        let last_loc = {
                            loading: false,
                            coordinates: coordinates,
                            address_results: [],
                            player_address: gen_addy?.player_address,
                            region: gen_addy.region,
                            location: gen_addy.location
                        };
                        LocationApi.cache_location(last_loc)
                        return setLocation(last_loc)
                    }
                }

                //Shoot! Couldnt get it - lets now get a new one!
                const addresses = await LocationApi.reverseCoordLookup(coordinates.latitude, coordinates.longitude);
                const generated_address = LocationHelpers.getAddressFromGeoCodeResults(addresses, locations);
                
                let new_loc = {
                    loading:false,
                    coordinates: coordinates,
                    address_results: addresses,
                    player_address: generated_address?.player_address,
                    region: generated_address?.region,
                    location: generated_address?.location
                }
                LocationApi.cache_location(new_loc)
                setLocation(new_loc)
                if(generated_address){
                    const player_location = LocationHelpers.genPlayerLocationFromAddress(coordinates, generated_address?.player_address, generated_address?.region, player_id, distinct_id)
                    LocationApi.savePlayerLocation(player_location)
                }
            }
            
        } catch (e) {
            setPermission({
                checked:true,
                permission: 'denied'
            })
            setLocation({
                loading:false,
                error:'Failed to identify your location.  Please try again later',
                coordinates: {},
                address_results: []
            })
        }
    }

    return { permission, location, getLocation, checkPermissions }
}

