import React, { useEffect, useState } from 'react';
import { View, TouchableOpacity, Image } from "react-native"
import { view_styles } from "../../constants/styles"
//import ImageUploader from '../../Components/ImageUploader';
import type { CompetitionProps, MyPlayerProps } from '../../types';
import { Button, Icons, Switch, Text, TextInput } from '../../Components';
import Colors from '../../constants/colors';
import ImageUploader from '../../Components/ImageUploader';
//import DropDown from '../../Components/Dropdown';

type CompetitionInfoFormProps = {
    competition:CompetitionProps,
    is_valid?:boolean,
    player?:MyPlayerProps,
    width: number,
    onCompetitionUpdate:(competition:CompetitionProps) => void
}

const CompetitionInfoForm = ({ is_valid, player, competition, width, onCompetitionUpdate }:CompetitionInfoFormProps) => {
    const [ expanded, setExpanded ] = useState(false);
    const [ draft_competition, setDraftCompetition ] = useState<CompetitionProps | undefined>(undefined);

    useEffect(() => {
        setDraftCompetition(competition)
    },[competition])

    if(!draft_competition){ return <></> }
    const is_changed = JSON.stringify(draft_competition) != JSON.stringify(competition) ? true : false;
    return (
        <View style={{ ...view_styles.section, flexGrow:1 }}>
            <TouchableOpacity style={{ ...view_styles.section_header, maxWidth:width}} onPress={() => setExpanded(!expanded)}>
                {is_valid ?
                <Icons.CheckCirlceIcon size={16} color={Colors.utility.success} />
                :
                <Icons.AlertIcon size={16} color={Colors.utility.warning} />
                }
                <View style={{ flex:1, marginLeft:10 }}>
                    <Text theme='header'>Competition Information</Text>
                    <Text style={{ marginTop:3 }} theme='body'>Manage the high level information of this competition</Text>
                </View>
                {is_changed ?
                <Button
                    title='SAVE'
                    padding={10}
                    title_color={Colors.shades.white}
                    backgroundColor={Colors.utility.success}
                    onPress={() => onCompetitionUpdate(draft_competition)}
                />
                :
                <Icons.ChevronIcon direction={expanded ? 'up' : 'down'} color={Colors.brand.midnight} size={8} />
                }
            </TouchableOpacity>
            {expanded ?
            <View nativeID="competition_info" style={{ ...view_styles.section_body, padding:10, minWidth:300, maxWidth:width, backgroundColor:Colors.shades.shade100 }}>
                
                <View nativeID="competition_image" style={{ ...view_styles.body_row, flexWrap:'wrap', maxWidth:width, borderBottomWidth:1, borderColor:Colors.shades.shade600 }}>
                    <View style={{ flex:1, margin:5, minWidth:200 }}>
                        <Text theme='header_2'>Competition Image</Text>
                        <Text style={{ marginTop:3 }} theme='body'>Give this competition an image so it is recognizable to those trying to join.</Text>
                    </View>
                    <ImageUploader
                        public_id={`comp_image_${competition.competition_id}_${Math.random()}`}
                        onFinishUpload={obj => onCompetitionUpdate({ ...draft_competition, image: { ...obj, url:obj.secure_url } })}
                    >
                        <Image
                            source={{ uri: competition.image?.url }}
                            style={{ height: 50, width:50, borderRadius:4 }}
                            resizeMode='cover'
                        />
                        <Text size={12} color={Colors.brand.electric} textAlign='center'>CHANGE</Text>
                    </ImageUploader>
                </View>

                <View nativeID="competition_name" style={{ ...view_styles.body_row, flexWrap:'wrap', maxWidth:width, borderBottomWidth:1, borderColor:Colors.shades.shade600 }}>
                    <View style={{ flex:1, margin:5, minWidth:200 }}>
                        <Text theme='header_2'>Competition Name</Text>
                        <Text style={{ marginTop:3 }} theme='body'>Give the competition a memorable name that aligns with the type and payout.</Text>
                    </View>
                    <TextInput
                        style={{ ...view_styles.input, flexGrow:1, margin:5 }}
                        onChangeText={(text) => setDraftCompetition({ ...draft_competition, competition_name:text })}
                        value={draft_competition.competition_name}
                        placeholder="Competition Name"
                        placeholderTextColor={Colors.brand.slate}
                    />
                </View>

                <View nativeID="competition_description" style={{ ...view_styles.body_row, flexWrap:'wrap', maxWidth:width, marginTop:10, borderBottomWidth:1, borderColor:Colors.shades.shade600 }}>
                    <View style={{ flex:1, margin:5, minWidth:200  }}>
                        <Text theme='header_2'>Competition Description</Text>
                        <Text style={{ marginTop:3 }} theme='body'>Give the competition a memorable description that would entice users to participate.</Text>
                    </View>
                    <TextInput
                        style={{ ...view_styles.input, flexGrow:1, margin:5 }}
                        onChangeText={(text) => setDraftCompetition({ ...draft_competition, competition_description:text })}
                        value={draft_competition.competition_description}
                        placeholder="Pick 5 games ATS from NFL Week 6 matchups"
                        placeholderTextColor={Colors.brand.slate}
                    />
                </View>

                <View nativeID="invite_only" style={{ ...view_styles.body_row, flexWrap:'wrap', marginTop:10, borderBottomWidth:1, borderColor:Colors.shades.shade600  }}>
                    <View style={{ flex:1, margin:5, marginRight:15 }}>
                        <Text theme='header_2'>Make Private</Text>
                        <Text style={{ marginTop:3 }} theme='body'>Only access this competition using an invite code.</Text>
                    </View>
                    <Switch
                        disabled={competition.competition_season_id ? true : false}
                        value={competition.invite_only}
                        switch_type="on_off"
                        onChange={(value) => onCompetitionUpdate({ ...draft_competition, invite_only: value })}
                    />
                </View>
                {player?.role == 'admin' ?
                <View nativeID="invite_only" style={{ ...view_styles.body_row, flexWrap:'wrap', marginTop:10, borderBottomWidth:1, borderColor:Colors.shades.shade600  }}>
                    <View style={{ flex:1, margin:5, marginRight:15 }}>
                        <Text theme='header_2'>Make Template</Text>
                        <Text style={{ marginTop:3 }} theme='body'>This will allow for other users to copy this competition and create it for themselves.</Text>
                    </View>
                    <Switch
                    
                        value={competition.template ? true : false}
                        switch_type="on_off"
                        onChange={(value) => onCompetitionUpdate({ ...draft_competition, template: value })}
                    />
                </View>
                :<></>}
            </View>
            :<></>}
        </View>
    )
}

export default CompetitionInfoForm

