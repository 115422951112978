import { ActivityIndicator, FlatList, Image, ScrollView, TouchableOpacity, View } from 'react-native';
import React, { useEffect, useState } from 'react';
import PollOptionCard from './PollOptionCard';
import ResponseTimer from './ResponseTimer';
import type { PollOptionProps, PollProps, PollResponseProps, PollSummaryProps } from '../../types';
import { PollApi, PollResponseApi } from '../api';
import { Button, Icons, Text } from '../../Components';
import Colors from '../../constants/colors';

type PollCardProps = {
    poll:PollProps,
    question_number?:number,
    onCancel:() => void,
    view?:string,
    onResponse:(response:PollResponseProps) => void,
    total_questions?:number,
    onShowAuthenticate: () => void,
    onSkip: () => void,
    onSeeResults?: () => void
}

const DEFAULT_POLL_STATE = { server_checked:false, responded:false, poll_response:undefined, poll_options:[], poll_summaries:[] }

const PollCard = ({ poll, question_number, total_questions, onSkip, onCancel, onSeeResults, onResponse }: PollCardProps) => {
    const [ loading, setLoading ] = useState(false);
    const [ submit_loading, setSubmitLoading ] = useState(false);
    const [ poll_width, setPollWidth ] = useState(0);
    const [ option_selected, setOptionSelected ] = useState<undefined|PollOptionProps>(undefined);
    const [ poll_data, setPollData ] = useState<{
        server_checked?:boolean,
        responded:boolean,
        poll_response?:PollResponseProps,
        poll_options:PollOptionProps[],
        poll_summaries:PollSummaryProps[]
    }>({
        server_checked:false,
        responded:false,
        poll_options:[],
        poll_summaries:[]
    })
    const { poll_options, poll_summaries, poll_response, responded, server_checked } = poll_data;
    const total_responses = poll_summaries.reduce((a,b) => a + b.count, 0)

    const correct_option = poll_options.find(po => po.result_ind == 'win')
    const selected_option = poll_options.find(po => po.poll_option_id == poll_response?.poll_option_id)

    const last_question = question_number && total_questions && question_number == total_questions ? true : false
   
    useEffect(() => {
        if(!poll){ return }
        getPollFromServer(poll.poll_id)
    },[poll])

    const getPollFromServer = async(poll_id:string) => {
        setLoading(true);
        let poll_response = await PollResponseApi.getResponseByPollId(poll_id)
        const pos_resp = await PollApi.getPollById(poll_id)
        setPollData({
            server_checked: true,
            responded: poll_response ? true : false,
            poll_response,
            poll_options:pos_resp.poll_options,
            poll_summaries: pos_resp.poll_summaries
        })
        setLoading(false);
    }

    const handleCancel = () => {
        setOptionSelected(undefined)

        onCancel()
    }

    const handleSkip = () => {
        //if(!is_authenticated){ return onShowAuthenticate() }
        setPollData(DEFAULT_POLL_STATE)
        setOptionSelected(undefined)
        if(last_question && onSeeResults){ return onSeeResults() }
        onSkip()

    }

    const handleSubmitResponse = async() => {
        //if(!is_authenticated){ return onShowAuthenticate() }
        if(submit_loading){ return }
        if(!poll || !server_checked || !option_selected){ return }
        if(responded || poll_response){ return }
        let option = poll_options.find(po => po.poll_option_id == option_selected.poll_option_id)
        if(!option){ return }
        //We are good to log the response!
        let draft_response:PollResponseProps = {
            poll_id:poll.poll_id,
            poll_option_id:option.poll_option_id,
            stake: poll.minimum_stake,
            market_type: 'FREE',
            poll_response_id: '',
            player_id: '',
            winnings: 0,
            response_body: {},
            status: 'pending',
            create_datetime: '',
            last_update_datetime: ''
        }
        if(option.option_type == 'input'){
            draft_response.input_value = option_selected.option_value
        }
        try {
            setSubmitLoading(true)
            let poll_response = await PollResponseApi.createPollResponse(draft_response)
            onResponse(poll_response)
            setPollData({
                ...poll_data,
                server_checked: true,
                responded: poll_response?true:false,
                poll_response
            })

            setOptionSelected(undefined)
            setSubmitLoading(false)
        } catch (e) {
            setSubmitLoading(false)
            setOptionSelected(undefined)
        }
    }

    const handleTimesUp = async() => {
        if(option_selected){
            return handleSubmitResponse()
        }
        if(!poll || poll.status != 'active'){ return }
        if(!server_checked){ return }
        if(responded){ return }
        const draft_response:PollResponseProps = {
            poll_id:poll.poll_id,
            poll_option_id:'0',
            stake: poll.minimum_stake,
            market_type: 'FREE',
            poll_response_id: '',
            player_id: '',
            winnings: 0,
            timed_out: true,
            response_body: {},
            status: 'pending',
            create_datetime: '',
            last_update_datetime: ''
        }
        setSubmitLoading(true)
        let poll_response = await PollResponseApi.createPollResponse(draft_response)
        onResponse(poll_response);
        setSubmitLoading(false)
        setPollData({
            ...poll_data,
            server_checked: true,
            responded: poll_response ? true : false,
            poll_response
        })
    }

    const renderPollOptions = (data: { item:PollOptionProps, index:number }) => {
        const poll_summary = poll_summaries.find(ps => ps.poll_option_id == data.item.poll_option_id);
        const draft_selected = option_selected?.poll_option_id == data.item.poll_option_id ? true : false;
        return (
            <View style={{ margin:5, flex:1 }}>
                <PollOptionCard
                    poll_summary={poll_summary}
                    draft_selected={draft_selected}
                    poll_response={poll_response}
                    poll_option={data.item}
                    show_summary={(server_checked && responded) && poll.show_responses ? true : false}
                    onSelect={(poll_option) => {
                        if(responded){ return }
                        if(poll?.status !== 'active'){ return }
                        if(draft_selected){ setOptionSelected(undefined) }
                        else { setOptionSelected(poll_option)} }
                    }
                />
            </View>
        )
    }

    if(!poll){
        return <></>
    }
    return (
        <View nativeID='poll' style={{ flex:1 }} onLayout={(ev) => {
            const { width } = ev.nativeEvent.layout
            setPollWidth(width)
        }}>
            <ScrollView style={{ flex:1 }}>
                {server_checked && !responded ?
                <View nativeID='question_image'>
                    {poll?.poll_image?.url ?
                    <Image
                        source={{ uri:poll.poll_image.url, width:poll_width, height: poll_width*0.5 }}
                        resizeMode='contain'
                    />
                    :<></>}
                </View>
                :<></>}
            <View nativeID='question_details' style={{padding:20}}>
                {question_number ?
                <View nativeID='question_progress' style={{ marginBottom:10, flexDirection:'row' }}>
                    <Text style={{ flex:1 }} size={12} color={Colors.brand.midnight} weight='semibold'>Question {question_number}{total_questions?` of ${total_questions}`:''}</Text>
                </View>
                :<></>}
                <View nativeID='question' style={{ marginBottom:10 }}>
                    <Text size={16} color={Colors.brand.midnight} weight='bold'>{poll.poll_question}</Text>
                </View>
            </View>
            { poll.status == 'active' && server_checked && !responded ?
            <View nativeID='question_respond_options' style={{ flex:1 }}>
                <FlatList
                    data={poll_options.sort((a,b) => a.priority - b.priority)}
                    renderItem={renderPollOptions}
                    numColumns={2}
                    keyExtractor={(item) => item.poll_option_id.toString()}
                />
            </View>
            : server_checked ?
            <View nativeID='question_answer' style={{ flex:1 }}>
                {selected_option ?
                <View nativeID='selected_option' style={{ marginBottom:10, padding:10 }}>
                    <Text style={{ marginBottom:5 }} size={14} color={Colors.brand.slate} weight='semibold'>MY SELECTED ANSWER</Text>
                    <PollOptionCard
                        show_result={true}
                        show_summary={false}
                        poll_response={poll_response}
                        onSelect={() => console.log('Hey')}
                        poll_option={selected_option}
                    />
                </View>
                : (!responded || poll_response?.timed_out) ?
                <View style={{ marginBottom:10, padding:10 }}>
                    <Text style={{ marginBottom:5 }} size={14} color={Colors.brand.slate} weight='semibold'>MY SELECTED ANSWER</Text>
                    <View style={{ flexDirection:'row', alignItems:'center', padding:10, borderRadius:22, borderWidth:1, borderColor:Colors.utility.error }}>
                        <Text style={{ flex:1 }} size={14} color={Colors.utility.error} textAlign='center' weight='semibold'>Timed Out</Text>
                        <Icons.CloseIcon color={Colors.utility.error} size={12}/>
                    </View>
                </View>
                :<></>}
                {poll.poll_type == 'select' && correct_option ? 
                <View nativeID='selected_option' style={{ marginBottom:10, padding:10 }}>
                    <Text style={{ marginBottom:5 }} size={14} color={Colors.brand.slate} weight='semibold'>CORRECT ANSWER</Text>
                    <PollOptionCard
                        show_summary={false}
                        show_result={true}
                        onSelect={() => console.log('Hey')}
                        poll_option={correct_option}
                    />
                </View>
                :poll.poll_type == 'input' && poll.winning_value ?
                <View style={{ marginBottom:10, padding:10 }}>
                    <Text style={{ marginBottom:5 }} size={14} color={Colors.brand.slate} weight='semibold'>CORRECT ANSWER</Text>
                    <View style={{ flexDirection:'row', alignItems:'center', padding:10, borderRadius:22, borderWidth:1, borderColor:Colors.utility.success }}>
                        <Text style={{ flex:1 }} size={14} color={Colors.utility.success} textAlign='center' weight='semibold'>{poll.winning_value}</Text>
                        <Icons.CheckIcon color={Colors.utility.success} size={12}/>
                    </View>
                </View>
                :<></>}
                {poll.poll_type == 'select' ?
                <View nativeID='option_results' style={{ backgroundColor:Colors.shades.shade100, padding:10, borderRadius:8 }}>
                    <View style={{ flexDirection:'row', alignItems:'center', marginBottom:5 }}>
                        <Text style={{ flex:1 }} size={14} color={Colors.brand.slate} weight='semibold'>PUBLIC RESULTS</Text>
                        <View style={{ borderRadius:22, padding:10, backgroundColor:Colors.accents.accent100 }}>
                            <Text size={14} color={Colors.brand.midnight} weight='regular'>{total_responses} Responses</Text>
                        </View>
                    </View>
                    {!poll.show_responses ?
                    <Text style={{ marginBottom:5 }} color={Colors.utility.warning} size={12} >Responses are hidden until closed</Text>
                    :<></>}
                    <FlatList
                        data={poll_options}
                        renderItem={renderPollOptions}
                        keyExtractor={(item) => item.poll_option_id.toString()}
                    />
                </View>
                :<></>}
            </View>
            :
            <View style={{ padding:20 }}>
                <ActivityIndicator color={Colors.brand.midnight} size='large' style={{ alignSelf:'center' }}/>
            </View>
            }
            </ScrollView>
            {!loading ?
            <View nativeID='question_actions' style={{ marginTop:20, borderRadius:22, flexDirection:'row', padding:10, backgroundColor:Colors.shades.shade100 }}>
                {!poll.seconds_allowed || responded ?
                <TouchableOpacity
                    style={{ marginRight:10, justifyContent:'center', paddingLeft:20, paddingRight:20, backgroundColor:Colors.utility.warning, borderRadius:22, borderWidth:1, borderColor:Colors.utility.warning }}
                    onPress={() => handleCancel()}
                >
                    <Icons.ListIcon size={18} color={Colors.shades.white} />
                </TouchableOpacity>
                :<></>}
                {poll.status == 'active' && poll.seconds_allowed && server_checked && !responded ?
                    <View style={{ marginRight:5 }}>
                        <ResponseTimer
                            poll_id={poll.poll_id}
                            seconds={poll.seconds_allowed}
                            onTimesUp={() => handleTimesUp()}
                        />
                    </View>
                :
                <Button
                    style={{flex:1, marginRight:5}}
                    title_color={Colors.brand.electric}
                    title={responded?'Next':'Skip'}
                    borderColor={Colors.brand.electric}
                    backgroundColor={Colors.shades.white}
                    borderRadius={22}
                    borderWidth={1}
                    padding={14}
                    onPress={() => handleSkip()}
                />
                }
                {server_checked && !responded ?
                <Button
                    style={{flex:2}}
                    title_color={Colors.shades.white}
                    disabled={option_selected&&!submit_loading?false:true}
                    title={option_selected?`Submit!`:poll.status == 'active' ? 'Select Answer' : 'Not Active'}
                    backgroundColor={option_selected&&!submit_loading?Colors.utility.success:Colors.brand.slate}
                    borderRadius={22}
                    onPress={() => handleSubmitResponse()}
                />
                :<></>}
            </View>
            :<></>}
        </View>
        
    )
}

export default PollCard