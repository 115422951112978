import React, { useEffect, useRef } from 'react';
import { Image, TouchableOpacity } from "react-native"
import type { AdTypeProps, CompanyAdvertisementProps } from '../../types';
import { useIsInViewport } from '../../Components/ViewportObserver';


type CompanyAdCardProps = {
    company_ad: CompanyAdvertisementProps
    size: { width: number, height:number }
    ad_type:AdTypeProps,
    onClickAd: (ad:CompanyAdvertisementProps) => void,
    onViewAd: (ad:CompanyAdvertisementProps) => void
}
const CompanyAdCard = ({ company_ad, size, ad_type, onClickAd, onViewAd }:CompanyAdCardProps) => {
    const ref = useRef(null)
    const isInViewport = useIsInViewport(ref)

    useEffect(() => {
        if(!isInViewport){ return }
        onViewAd(company_ad)
    },[isInViewport])

    const getSize = () => {
        if(!company_ad){ return { width:0, height:0 } }
        let ratio = ad_type.height / ad_type.width
        let buffer = size.width - ad_type.width
        if(buffer < 30){
            let new_width = size.width - 30
            return { width: new_width, height: new_width * ratio }
        }
        return { width:ad_type.width, height:ad_type.height }
    }
    const { width, height } = getSize();
    return (
        <TouchableOpacity ref={ref} onPress={() => onClickAd(company_ad)}>
            <Image
                source={{ uri: company_ad.ad_creative.url }}
                style={{ width, height }}
                resizeMode='cover'
            />
        </TouchableOpacity>
    )
}


export default CompanyAdCard